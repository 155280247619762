import React, { useState } from 'react';
import { api } from '../constants';

const AdminPlayer = props => {
  const { idItem } = props;
  const [erro, setErro] = useState(false);
  const [ready, setReady] = useState(false);

  const played = () => {
    const aud = document.getElementById(`audio-id${idItem}`);
    console.log('duration', aud.duration);
    setTimeout(() => {
      setReady(false);
    }, aud.duration * 1100 || 4000);
  };

  const ended = () => {
    const aud = document.getElementById(`audio-id${idItem}`);
    console.log('duration', aud.duration);
    setReady(false);
  };

  if (erro) {
    return <i className="fa fa-times fa-lg text-danger" alt="Erro ao carregar áudio."></i>;
  }

  if (ready) {
    const servico = api.AUDIO;
    const url = servico.url(idItem);
    return (
      <>
        <audio id={`audio-id${idItem}`} autoPlay onEnded={() => ended()} onPlay={() => played()} onError={() => setErro(true)}>
          <source src={url} type="audio/ogg" />
          Seu navegador não suporta este formato de áudio.
        </audio>
        <i className="fa fa-spinner fa-spin fa-lg"></i>
      </>
    );
  }
  return <i className="fa fa-play-circle fa-lg" onClick={() => setReady(true)}></i>;
};

export default AdminPlayer;
