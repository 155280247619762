import { F1, F2, M1, M2 } from '../assets/svgs/avatar';

const avatares = [
  { value: 'M1', image: M1 },
  { value: 'M2', image: M2 },
  { value: 'F1', image: F1 },
  { value: 'F2', image: F2 },
];

export default avatares;
