import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HomePage } from './containers/home';
import { Cadastro, Esqueci, Login, Logout } from './containers/auth';
import { Inicio } from './containers/inicio';
import { Administracao } from './containers/admin';
import { Apresentacao, Configuracoes, OpcoesApresentacao, SelecaoPrograma } from './containers/apresentacao';
import { AdicionarCrianca } from './containers/criancas';

import { firebaseConfig } from './constants';
import { NoMatch, PrivateRoute, Carregando } from './components';
import { Auth } from './services';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Configurações do Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

// Configurações do toastify
toast.configure();

// const RouterApp = () => {
//   const location = useLocation();
//   const [carregando, setCarregando] = useState(true);
//   const funci = new Admin();

//   // Controle de do perfil de acesso
//   useEffect(() => {
//     const inicializar = async () => {
//       // Recupera os perfis do usuário caso ainda não tenham sido obtidos
//       // if (!funci.perfisCarregados) {
//       //   await funci.atualizarPerfisUsuario();
//       // }
//       setCarregando(false);
//     };
//     inicializar();
//   }, []);

//   if (carregando) return <Carregando />;

//   return (
//     <MemoryRouter
//       getUserConfirmation={(message, callback) => {
//         const allowTransition = window.confirm(message);
//         callback(allowTransition);
//       }}
//     >
//       <div className="d-flex flex-column w-100 m-0" style={{ height: '100vh', width: '100vw' }}>
//         <Cabecalho location={location} />

//         <Row className="w-100 flex-grow-1 dark">
//           <Col as="div" className="rolagem">
//             <Container fluid={true}>
//               <Switch>
//                 {/* ************************************************************************************* */}
//                 {/* LOGOUT */}
//                 {/* ************************************************************************************* */}
//                 <Route exact path="/logout" component={Logout} />

//                 {/* ************************************************************************************* */}
//                 {/* HOME */}
//                 {/* ************************************************************************************* */}
//                 <Route exact path="/">
//                   <HomePage />
//                 </Route>

//                 {/* ************************************************************************************* */}
//                 {/* NOMATCH */}
//                 {/* ************************************************************************************* */}
//                 <Route path="*">
//                   <NoMatch />
//                 </Route>
//               </Switch>
//             </Container>
//           </Col>
//         </Row>
//       </div>
//     </MemoryRouter>
//   );
// };

function App() {
  const [carregando, setCarregando] = useState(true);
  const [authed, setAuthed] = useState(true);

  // Controle de status do login
  useEffect(() => {
    const inicializar = async () => {
      const auth = new Auth();
      auth.setAuthFunction(setAuthed);
      auth.setAuth(auth.authed);
      setCarregando(false);
    };
    inicializar();
  }, []);

  if (carregando) {
    return <Carregando />;
  }

  return (
    <BrowserRouter>
      <ToastContainer newestOnTop style={{ width: '500px', marginTop: 50 }} />
      <div className="d-flex flex-column w-100 m-0" style={{ height: '100vh', width: '100vw' }}>
        <Switch>
          <HomePage exact authed={authed} path="/" />
          {/* Auth */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/esqueci" component={Esqueci} />
          <Route exact path="/cadastro" component={Cadastro} />
          <Route exact path="/sair" component={Logout} />

          {/* Area Logada */}
          <PrivateRoute exact authed={authed} path="/inicio" component={Inicio} />
          <PrivateRoute exact authed={authed} path="/adicionar-crianca" component={AdicionarCrianca} />
          <PrivateRoute authed={authed} path="/apresentacao" component={Apresentacao} />
          <PrivateRoute authed={authed} path="/opcoes-apresentacao" component={OpcoesApresentacao} />
          <PrivateRoute authed={authed} path="/configuracoes" component={Configuracoes} />
          <PrivateRoute authed={authed} path="/selecao-programa" component={SelecaoPrograma} />

          {/* Admin */}
          <PrivateRoute authed={authed} path="/admin" component={Administracao} />

          {/* Misc */}
          <Route path="*" component={NoMatch} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
