import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const Confirmacao = props => {
  const { show = false, mensagem = '', onConfirm = () => {}, onCancel = () => {} } = props;

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} onHide={onCancel} className="bg-invisible">
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`${mensagem}`}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default Confirmacao;
