import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Carregando } from '../../components';
import { Auth, Requisicoes } from '../../services';

export default withRouter(props => {
  const { history } = props;

  useEffect(() => {
    const inicializar = async () => {
      await Requisicoes.getSessionLogout();
      const auth = new Auth();
      auth.setAuth(false);
      history.replace('/');
    };
    inicializar();
  }, [history]);

  return <Carregando />;
});
