import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AdmDadosAcervo, AdmDadosProgramas, AdmDadosGrupos } from '.';
import { useQuery } from '../../utils';

export default props => {
  const query = useQuery();
  const aba = query.get('aba');

  return (
    <>
      <br />
      <Tabs defaultActiveKey={aba || 'programas'} id="uncontrolled-tab">
        <Tab mountOnEnter unmountOnExit variant="pills" eventKey="programas" title="Programas">
          <AdmDadosProgramas query={query} />
        </Tab>
        <Tab mountOnEnter unmountOnExit variant="pills" eventKey="grupos" title="Grupos">
          <AdmDadosGrupos query={query} />
        </Tab>
        <Tab mountOnEnter unmountOnExit variant="pills" eventKey="acervo" title="Acervo">
          <AdmDadosAcervo query={query} />
        </Tab>
      </Tabs>
    </>
  );
};
