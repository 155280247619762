import moment from 'moment';
import { modelCrianca, modelLog } from '.';

import { validarEmail } from '../utils';

/**
 * @method modelUsuario
 * @description Modelo padrão de objeto da classe Usuario
 * @param {string} uid - Id do usuário
 * @param {string} instanceid - Id da instancia do app que o usuário logou pela ultima vez
 * @param {string} nome - Nome
 * @param {string} nomeReduzido - Nome reduzido/apelido
 * @param {string} email - Email cadastrado
 * @param {string} cpf - Cpf cadastrado
 * @param {string} telefone - telefone cadastrado
 * @param {boolean} recupSenhaAtiva - Indicador de recuperação de senha
 * @param {string} versaoTermoAceito - Versão do termo aceito
 * @param {timestamp} dataAceiteTermo - Data de aceite dos termos de uso
 * @param {boolean} admin - Indicador de status admin
 * @param {string} status - Status do usuário [I - Inativo - A - Ativo]
 * @param {string} statusLabel - Label do status do usuário [I - Inativo - A - Ativo]
 * @param {timestamp} timestamp - Data de cadastro
 * @param {number} qtdeCriancas - Quantidade de crianças atualmente cadastradas (ativas)
 * @param {number} qtdeCriancasMatriculadas - Quantidade de crianças atualmente cadastradas e matriculadas (ativas)
 * @param {timestamp} dtUltimaAtividade - Data da última atividade na aplicação
 * @returns {object} Objeto de Usuário formatado
 */
export default ({
  uid,
  instanceid = '',
  nome = '',
  nome_reduzido = '',
  email,
  cpf = '',
  telefone = '',
  recupSenhaAtiva = false,
  versaoTermoAceito = '',
  dataAceiteTermo = null,
  admin = false,
  timestamp = new Date(),
  status = 'I',
  qtde_criancas = 0,
  qtde_criancas_matriculadas = 0,
  dt_ultima_atividade = null,
  criancas = [],
  logs = [],
}) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'I') return 'INATIVO';
    if (status === 'S') return 'SUSPENSO';
    if (status === 'C') return 'CANCELADO';
    return 'INVÁLIDO';
  };
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);

  return {
    uid,
    instanceid,
    nome,
    nomeReduzido: nome_reduzido,
    email: validarEmail(email) ? email : '',
    cpf,
    telefone,
    recupSenhaAtiva,
    versaoTermoAceito,
    dataAceiteTermo: getDataHora(dataAceiteTermo),
    admin,
    status,
    statusLabel: getStatusLabel(),
    timestamp: getDataHora(timestamp),
    qtdeCriancas: qtde_criancas,
    qtdeCriancasMatriculadas: qtde_criancas_matriculadas,
    dtUltimaAtividade: getDataHora(dt_ultima_atividade),
    criancas: criancas.map(c => modelCrianca(c)) || criancas,
    logs: logs.map(l => modelLog(l)) || logs,
  };
};
