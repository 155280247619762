import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useState, useEffect } from 'react';
import { Col, Row, Button, Form, Container, Card, Image } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { Cabecalho, Carregando } from '../../components';
import { Usuario } from '../../services';
import avatares from '../../constants/avatares';
import { alertar } from '../../utils';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const { handleSubmit, register, errors, reset, control } = useForm();
  const [grausAfinidade, setGrausAfinidade] = useState([]);
  const [done, setDone] = useState(false);

  const onSubmit = async values => {
    const { nome, apelido, avatar, sexo, nascimento, afinidade } = values;

    setCarregando(true);

    try {
      const crianca = { nome, sexo, avatar, nomeReduzido: apelido, dataNascimento: nascimento.format('DD/MM/YYYY'), idGrauAfinidade: afinidade };

      const resp = await Usuario.adicionarCrianca(crianca);
      if (resp === true) {
        setDone(true);
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a inclusão da criança. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      const graus = await Usuario.listarGrausAfinidade();
      if (graus.length >= 1 && graus[0].descricao) {
        setGrausAfinidade(graus);
      }
      if (graus.erro) {
        alertar('error', graus.erro || 'Erro ao recuperar a lista de graus de afinidade. Por favor, atualize a página.');
      }
      setCarregando(false);
    };
    inicializar();
  }, []);

  if (done) {
    return <Redirect to="/inicio" />;
  }

  return (
    <>
      <Cabecalho location={location} />
      <Container fluid className="h-100 d-flex flex-column align-items-center pt-4">
        <Carregando show={carregando} />
        <br />
        <Card className="border-warning text-center" style={{ width: '50vw', minWidth: '400px' }}>
          <Card.Body>
            <Card.Title className="text-center">ADICIONAR CRIANÇA</Card.Title>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-2 d-flex flex-column align-items-center">
              {/* Avatar */}
              <Form.Group controlId="avatar" className="mb-4 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Avatar
                </Form.Label>
                <Col xs="auto" className="d-flex">
                  {avatares.map((avatar, index) => (
                    <Col xs="auto" className="d-flex flex-column" key={index.toString()}>
                      <Image src={avatar.image} style={{ borderRadius: '2vw', width: '5vw', aspectRatio: '114,15%' }} />
                      <Form.Check name="avatar" type="radio" value={avatar.value} ref={register({ required: 'Selecione um avatar.' })} />
                    </Col>
                  ))}
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.avatar && errors.avatar.message}
                </Col>
              </Form.Group>
              {/* Nome */}
              <Form.Group controlId="nome" className="mb-2 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Nome da criança
                </Form.Label>
                <Col xs="auto">
                  <Form.Control
                    name="nome"
                    type="text"
                    maxLength={150}
                    ref={register({
                      required: 'É necessário informar o nome da criança.',
                      minLength: { value: 5, message: 'O nome deve conter no mínimo 5 caracteres.' },
                      maxLength: { value: 150, message: 'O nome deve conter no máximo 150 caracteres.' },
                    })}
                  />
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.nome && errors.nome.message}
                </Col>
              </Form.Group>
              {/* Apelido */}
              <Form.Group controlId="apelido" className="mb-2 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Apelido
                </Form.Label>
                <Col xs="auto">
                  <Form.Control
                    name="apelido"
                    type="text"
                    maxLength={20}
                    ref={register({
                      maxLength: { value: 20, message: 'O apelido deve conter no máximo 20 caracteres.' },
                    })}
                  />
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.apelido && errors.apelido.message}
                </Col>
              </Form.Group>
              {/* Sexo */}
              <Form.Group controlId="sexo" className="mb-2 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Sexo
                </Form.Label>
                <Col xs="auto" className="d-flex border rounded">
                  <Form.Check
                    className="mr-4"
                    name="sexo"
                    type="radio"
                    value="M"
                    label="Masculino"
                    ref={register({ required: 'Informe o sexo da criança.' })}
                  />
                  <Form.Check
                    className="ml-4"
                    name="sexo"
                    type="radio"
                    value="F"
                    label="Feminino"
                    ref={register({ required: 'Informe o sexo da criança.' })}
                  />
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.sexo && errors.sexo.message}
                </Col>
              </Form.Group>

              {/* Nascimento */}
              <Form.Group controlId="nascimento" className="mb-2 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Data de Nascimento
                </Form.Label>
                <Col xs="auto">
                  <Controller
                    control={control}
                    name="nascimento"
                    defaultValue=""
                    rules={{
                      required: 'Informe a data de nascimento da criança.',
                      validate: value => moment(value).isValid || 'Data inválida.',
                    }}
                    render={dateprops => (
                      <DatePicker
                        input
                        locale="pt-br"
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputRef={dateprops.ref}
                        value={dateprops.value}
                        onChange={dateprops.onChange}
                      />
                    )}
                  />
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.nascimento && errors.nascimento.message}
                </Col>
              </Form.Group>

              {/* Grau de afinidade */}
              <Form.Group controlId="afinidade" className="mb-2 d-flex flex-column align-items-center">
                <Form.Label column sm="auto">
                  Grau de afinidade
                </Form.Label>
                <Col xs="auto">
                  <Form.Control
                    as="select"
                    name="afinidade"
                    defaultValue={''}
                    ref={register({ required: 'Informe o grau de afinidade com a criança.' })}
                  >
                    <option value={''}>Selecione...</option>
                    {grausAfinidade.map(grau => (
                      <option key={grau.id.toString()} value={grau.id}>
                        {grau.descricao}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col as={Card.Text} className="text-danger">
                  {errors.afinidade && errors.afinidade.message}
                </Col>
              </Form.Group>

              {/* Botões */}
              <Row className="justify-content-center mt-4">
                <Button className="mx-2" variant="outline-primary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="outline-dark" onClick={() => reset()}>
                  Limpar
                </Button>
                <Button className="mx-2" variant="primary" type="submit">
                  Incluir
                </Button>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
