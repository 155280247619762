import { toast } from 'react-toastify';
import { validarTipo } from '.';

/**
 * alertar
 *
 * @param {string} tipo [success, info, warning, error, dark, default]
 * @param {string} mensagem
 * @returns
 */
const alertar = (tipo = 'error', mensagem = '') => {
  // let width = 300;
  // if (validarTipo(mensagem, 'string')) {
  //   const tamLetra = 8.3;
  //   const pixelLength = mensagem.length * tamLetra;
  //   const maxWidth = pixelLength > 580 ? 580 : pixelLength;
  //   width = maxWidth < width ? width : maxWidth;
  // }

  const defaults = {
    position: 'top-right',
    autoClose: 5000,
    newestOnTop: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    // style: { width: `${width}px` },
  };

  if (tipo === 'success') return toast.success(`${mensagem}`, defaults);
  if (tipo === 'info') return toast.info(`${mensagem}`, defaults);
  if (tipo === 'error') return toast.error(`${mensagem}`, defaults);
  if (tipo === 'warning') return toast.warning(`${mensagem}`, defaults);
  if (tipo === 'dark') return toast.dark(`${mensagem}`, defaults);
  if (tipo === 'default') return toast(`${mensagem}`, defaults);
  return toast.error(`${mensagem}`, defaults);
};

export default alertar;
