import moment from 'moment';

/**
 * @method modelGrupo
 * @description Modelo padrão de objeto da classe
 * @param {string} codigo - código do grupo
 * @param {string} nome - nome
 * @param {string} descricao - descrição
 * @param {string} nivel - nível
 * @param {string} status - status
 * @param {string} statusLAbel - status formatado
 * @param {array} items - lista de itens vinculados
 * @param {timestamp} timestamp - momento do registro
 * @returns {object} Objeto formatado
 */
export default ({ codigo, nome = '', descricao = '', nivel = '', status = '', itens = [], timestamp = null }) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'I') return 'INATIVO';
    if (status === 'S') return 'SUSPENSO';
    if (status === 'C') return 'CANCELADO';
    return 'INVÁLIDO';
  };
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);

  return {
    codigo,
    nome,
    descricao,
    nivel,
    status,
    statusLabel: getStatusLabel(),
    itens,
    timestamp: getDataHora(timestamp),
  };
};
