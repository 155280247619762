import React, { useState } from 'react';
import { Container, Card, Form, Button, Row } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { Carregando } from '../../components';
import { Auth } from '../../services';
import { alertar, validarEmail } from '../../utils';

export default withRouter(props => {
  const [carregando, setCarregando] = useState(false);
  const [validatedForm, setValidatedForm] = useState(false);
  const [done, setDone] = useState(false);
  const [email, setEmail] = useState('');
  const history = useHistory();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) recuperar();
    setValidatedForm(true);
  };

  const recuperar = async () => {
    setCarregando(true);
    try {
      if (!validarEmail(email)) {
        setValidatedForm(false);
        return alertar('error', 'Email inválido.');
      }

      // Recuperar Senha
      const resp = await Auth.recuperarSenha(email);
      if (resp === true) {
        setDone(true);
        setCarregando(false);
      } else {
        const erro = { msg: 'Erro ao encaminhar o e-mail de recuperação. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setValidatedForm(false);
      setCarregando(false);
    }
  };

  if (done) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
        <Container style={{ minWidth: 300 }}>
          <Card className="text-center" xs="auto">
            <Card.Body>
              <Card.Title className="text-center">RECUPERAÇÃO DE SENHA</Card.Title>
              <Card.Text>Uma mensagem com o link de recuperação foi encaminhada para seu e-mail.</Card.Text>
              <Card.Text>Caso não chegue em alguns minutos, verifique a caixa de Spam ou se há algum bloqueio em sua conta de e-mail.</Card.Text>

              <Button variant="outline-primary" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
      <Container style={{ minWidth: 300 }}>
        <Carregando show={carregando} />
        <br />
        <Card className="text-center" xs="auto">
          <Card.Body>
            <Card.Title className="text-center mb-4">RECUPERAÇÃO DE SENHA</Card.Title>
            <Card.Text>Informe o e-mail cadastrado:</Card.Text>
            <Form noValidate validated={validatedForm} onSubmit={handleSubmit}>
              <Form.Group xs={4} controlId="email" className="">
                <Form.Control required type="text" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Email inválido'}</Form.Control.Feedback>
              </Form.Group>

              <Row className="mt-4 d-flex justify-content-center">
                <Button className="mx-2" variant="outline-primary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="primary" type="submit">
                  Recuperar
                </Button>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
});
