import env from './env';

const build = endpoint => `${env.URL_API}${endpoint}`;

const api = {
  // UTILS
  CSRFTOKEN: { url: () => build('/csrftoken'), metodo: 'GET' },
  // AUDIO
  AUDIO: { url: idItem => build(`/v1/itemAudio/${idItem}`), metodo: 'GET' },
  // IMAGEM
  IMAGEM: { url: idItem => build(`/v1/itemImagem/${idItem}`), metodo: 'GET' },
  // USUARIO
  SESSION_LOGIN: { url: () => build('/v1/sessionLogin'), metodo: 'POST' },
  SESSION_LOGOUT: { url: () => build('/v1/sessionLogout'), metodo: 'GET' },
  CADASTRO_USUARIO: { url: () => build('/v1/usuario'), metodo: 'POST' },
  ADMIN_LISTAR_USUARIOS: { url: () => build('/v1/admin/usuarios'), metodo: 'GET' },
  ADMIN_CONSULTAR_USUARIOS: { url: uid => build(`/v1/admin/usuario/${uid}`), metodo: 'GET' },
  ADMIN_ALTERAR_STATUS_USUARIO: { url: uid => build(`/v1/admin/usuario/${uid}`), metodo: 'PATCH' },
  // CRIANCAS
  USUARIO_LISTAR_CRIANCAS: { url: () => build('/v1/criancas'), metodo: 'GET' },
  USUARIO_CONSULTAR_CRIANCA: { url: idCrianca => build(`/v1/crianca/${idCrianca}`), metodo: 'GET' },
  USUARIO_LISTAR_GRAUS_AFINIDADE: { url: () => build('/v1/graus-afinidade'), metodo: 'GET' },
  USUARIO_ADICIONAR_CRIANCA: { url: () => build('/v1/crianca'), metodo: 'POST' },
  ADMIN_ALTERAR_STATUS_MATRICULA: { url: id => build(`/v1/admin/matricula/${id}`), metodo: 'PATCH' },
  ADMIN_ALTERAR_STATUS_CRIANCA: { url: id => build(`/v1/admin/crianca/${id}`), metodo: 'PATCH' },
  ADMIN_ADICIONAR_CRIANCA: { url: () => build('/v1/admin/crianca'), metodo: 'POST' },
  // CONTEUDO
  USUARIO_CONSULTAR_CONTEUDO: { url: (idCrianca, codigoPrograma) => build(`/v1/conteudo/${idCrianca}/${codigoPrograma}`), metodo: 'GET' },
  // GRUPOS
  ADMIN_ADICIONAR_GRUPO: { url: () => build('/v1/admin/grupo'), metodo: 'POST' },
  ADMIN_LISTAR_GRUPOS: { url: () => build('/v1/admin/grupos'), metodo: 'GET' },
  ADMIN_CONSULTAR_GRUPO: { url: codigo => build(`/v1/admin/grupo/${codigo}`), metodo: 'GET' },
  ADMIN_ALTERAR_GRUPO: { url: () => build('/v1/admin/grupo'), metodo: 'PATCH' },
  ADMIN_EXCLUIR_GRUPO: { url: codigo => build(`/v1/admin/grupo/${codigo}`), metodo: 'DELETE' },
  // PROGRAMAS
  ADMIN_ADICIONAR_PROGRAMA: { url: () => build('/v1/admin/programa'), metodo: 'POST' },
  ADMIN_LISTAR_PROGRAMAS: { url: () => build('/v1/admin/programas'), metodo: 'GET' },
  ADMIN_CONSULTAR_PROGRAMA: { url: codigo => build(`/v1/admin/programa/${codigo}`), metodo: 'GET' },
  ADMIN_ALTERAR_PROGRAMA: { url: () => build('/v1/admin/programa'), metodo: 'PATCH' },
  ADMIN_EXCLUIR_PROGRAMA: { url: codigo => build(`/v1/admin/programa/${codigo}`), metodo: 'DELETE' },
  ADMIN_VINC_GRUPO_PROGRAMA: { url: () => build(`/v1/admin/grupo-programa`), metodo: 'PATCH' },
  ADMIN_VINC_ITEM_GRUPO: { url: () => build(`/v1/admin/item-grupo`), metodo: 'PATCH' },
  // ITEMS
  ADMIN_ADICIONAR_ITEM: { url: () => build('/v1/admin/item'), metodo: 'POST' },
  ADMIN_LISTAR_ITENS: { url: () => build('/v1/admin/itens'), metodo: 'GET' },
  ADMIN_CONSULTAR_ITEM: { url: id => build(`/v1/admin/item/${id}`), metodo: 'GET' },
  ADMIN_ALTERAR_ITEM: { url: () => build('/v1/admin/item'), metodo: 'PATCH' },
  ADMIN_EXCLUIR_ITEM: { url: id => build(`/v1/admin/item/${id}`), metodo: 'DELETE' },
  // TESTE
  TESTE: { url: () => build('/teste'), metodo: 'GET' },

  // ANTIGOS _ DELETAR
  INFO: { url: () => build('/v1/info'), metodo: 'GET' },
  IS_FUNCI: { url: email => build(`/v1/funci/isfunci/${email}`), metodo: 'GET' },
  LOGS: { url: () => build('/v1/funci/logs'), metodo: 'POST' },
  TURNOS: { url: () => build('/v1/funci/turnos'), metodo: 'GET' },
  SOCIO_CONSULTAR: { url: titulo => build(`/v1/funci/socio/${titulo}`), metodo: 'GET' },
  CONVITES_DISP_AVULSO: { url: titulo => build(`/v1/funci/convites/disponivelAvulso/${titulo}`), metodo: 'GET' },
  CONVITES_DISP_CHURRAS: { url: (ano, titulo) => build(`/v1/funci/convites/disponivelChurrasqueira/${ano}/${titulo}`), metodo: 'GET' },
  CONVITES_DISP_CHURRAS_RESERVA: {
    url: (idReserva, ano) => build(`/v1/funci/convites/disponivelChurrasqueiraReservada/${idReserva}/${ano}`),
    metodo: 'GET',
  },
  CONVITES_EMITIR: { url: () => build(`/v1/funci/convites`), metodo: 'POST' },
  CONVITES_LISTAR: { url: titulo => build(`/v1/funci/convites/${titulo}`), metodo: 'GET' },
  CONVITE: { url: idConvite => build(`/v1/funci/convite/${idConvite}`), metodo: 'GET' },
  CONVITE_ENTRADA: { url: () => build(`/v1/funci/entradaConvidado`), metodo: 'POST' },
  CONVITE_ALTERAR: { url: () => build(`/v1/funci/convites`), metodo: 'PATCH' },
  CONVITE_EMAIL: { url: () => build(`/v1/funci/enviarconvite`), metodo: 'POST' },
  CHURRASQUEIRAS_LISTAR: { url: () => build(`/v1/funci/churrasqueiras`), metodo: 'GET' },
  CHURRASQUEIRAS_CONSULTAR: { url: idChurrasqueira => build(`/v1/funci/churrasqueiras/${idChurrasqueira}`), metodo: 'GET' },
  CHURRASQUEIRAS_ALTERAR: { url: () => build(`/v1/funci/churrasqueiras`), metodo: 'PATCH' },
  RESERVAS_LISTAR: { url: (dataInicial, dataFinal, titulo) => build(`/v1/funci/reservas/${dataInicial}/${dataFinal}/${titulo}`), metodo: 'GET' },
  RESERVAS_CONSULTAR: { url: idReserva => build(`/v1/funci/reserva/${idReserva}`), metodo: 'GET' },
  RESERVAS_ALTERAR: { url: () => build(`/v1/funci/reservas`), metodo: 'PATCH' },
  RESERVAS_DISP_CHURRASQ: { url: data => build(`/v1/funci/reservas/churrasqueirasDisponiveis/${data}`), metodo: 'GET' },
  RESERVAS_DISP_DATAS: { url: () => build(`/v1/funci/reservas/datasDisponiveis`), metodo: 'GET' },
  RESERVAS_CONVITES: { url: idReserva => build(`/v1/funci/convites-reserva/${idReserva}`), metodo: 'GET' },
  RESERVAS_EMITIR: { url: () => build(`/v1/funci/reservas`), metodo: 'POST' },
  USUARIOS_SIS_LISTAR: { url: titulo => build(`/v1/funci/usuarios-sistema/titulo/${titulo}`), metodo: 'GET' },
  USUARIOS_SIS_CONSULTAR: { url: uid => build(`/v1/funci/usuarios-sistema/uid/${uid}`), metodo: 'GET' },
  USUARIOS_SIS_ALTERAR: { url: () => build(`/v1/funci/usuarios-sistema`), metodo: 'PATCH' },
  USUARIOS_SIS_PERFIS_USUARIO: { url: uid => build(`/v1/funci/perfis-acesso/${uid}`), metodo: 'GET' },
  USUARIOS_SIS_PERFIS: { url: () => build(`/v1/funci/perfis-sistema`), metodo: 'GET' },
  ACESSOS_LISTAR: {
    url: (dataInicial, dataFinal, tipoIdentificador, identificador) =>
      build(`/v1/funci/acessos-fisicos/${dataInicial}/${dataFinal}/${tipoIdentificador}/${identificador}`),
    metodo: 'GET',
  },
  /** APROVEITAR */
  TERCEIROS: { url: () => build('/v1/terceiros'), metodo: 'GET' },
  SALT: { url: email => build(`/v1/login/salt/${email}`), metodo: 'GET' },
  LOGIN: { url: () => build('/v1/login'), metodo: 'POST' },
  CONVITES_DISP_CHURRASQ: { url: (uid, ano) => build(`/v1/convites/disponivelChurrasqueira/${uid}/${ano}`), metodo: 'GET' },
};

export default api;
