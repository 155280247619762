import moment from 'moment';
import modelPrograma from './modelPrograma';

/**
 * @method modelCrianca
 * @description Modelo padrão de objeto da classe
 * @param {string} uid - Id do usuário
 * @param {string} id - Id da crianca
 * @param {string} cpf - Cpf cadastrado
 * @param {string} nome - Nome
 * @param {string} nomeReduzido - Nome reduzido/apelido
 * @param {string} avatar - avatar escolhido
 * @param {boolean} matriculada - status de matrícula na escola
 * @param {timestamp} dataNascimento - Data de nascimento
 * @param {string} idade - idade da crianca em anos
 * @param {string} idGrauAfinidade - id grau afinidade
 * @param {timestamp} grau_afinidade - grau afinidade
 * @param {boolean} admin - Indicador de status admin
 * @param {array} programas - programas que a criança pode acessar
 * @param {string} status - Status da criança [I - Inativo - A - Ativo]
 * @param {string} statusLabel - Label do status da criança [I - Inativo - A - Ativo]
 * @param {string} statusRelacionamento - Status do vinculo [I - Inativo - A - Ativo]
 * @param {string} statusRelacionamentoLabel - Label do status do vinculo [I - Inativo - A - Ativo]
 * @param {string} statusAfinidade - Status da afinidade [I - Inativo - A - Ativo]
 * @param {string} statusAfinidadeLabel - Label do status da afinidade [I - Inativo - A - Ativo]
 * @param {timestamp} timestamp - Data de cadastro da crianca
 * @param {timestamp} relacionamentoTimestamp - Data de cadastro da crianca
 * @returns {object} Objeto formatado
 */
export default ({
  uid,
  id,
  cpf = '',
  nome = '',
  nome_reduzido = '',
  sexo = '',
  avatar = '',
  matriculada = false,
  data_nascimento = null,
  id_grau_afinidade = 0,
  grau_afinidade = '',
  in_grupo_familiar = false,
  programas = [],
  status = 'I',
  status_relacionamento = 'I',
  afinidade_status = 'I',
  timestamp = null,
  relacionamento_timestamp = null,
}) => {
  const getStatusLabel = stat => {
    if (stat === 'A') return 'ATIVO';
    if (stat === 'I') return 'INATIVO';
    if (stat === 'S') return 'SUSPENSO';
    if (stat === 'C') return 'CANCELADO';
    return 'INVÁLIDO';
  };
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);
  const getData = data => (data ? moment(data).format('DD/MM/YYYY') : null);
  const getIdade = () => moment().diff(moment(data_nascimento), 'years');

  return {
    uid,
    id,
    cpf,
    nome,
    nomeReduzido: nome_reduzido,
    sexo,
    avatar,
    matriculada,
    dataNascimento: getData(data_nascimento),
    idade: getIdade(),
    idGrauAfinidade: id_grau_afinidade,
    grauAfinidade: grau_afinidade,
    inGrupoFamiliar: in_grupo_familiar,
    programas: programas.length > 0 ? programas.map(p => modelPrograma(p)) : [],
    status,
    statusLabel: getStatusLabel(status),
    statuRelacionamento: status_relacionamento,
    statuRelacionamentoLabel: getStatusLabel(status_relacionamento),
    afinidadeStatus: afinidade_status,
    afinidadeStatusLabel: getStatusLabel(afinidade_status),
    timestamp: getDataHora(timestamp),
    relacionamentoTimestamp: getDataHora(relacionamento_timestamp),
  };
};
