import { validarTipo } from '../utils';
import Requisicoes from './Requisicoes';

/**
 * Usuario
 * Classe singleton
 *
 * @export
 * @class Usuario
 */
export default class Usuario {
  /**
   * Cria uma instância singleton da classe Usuario.
   * @memberof Usuario
   */
  constructor() {
    if (!Usuario.instance) {
      this.autenticado = false;
      this.perfis = [];
      this.perfisCarregados = false;
      Usuario.instance = this;
    }
    return Usuario.instance;
  }

  /**
   * isAutenticado
   *
   * @returns
   * @memberof Usuario
   */
  isAutenticado() {
    return this.autenticado;
  }

  /**
   * isAdmin
   *
   * @static
   * @param {string} email
   * @memberof Usuario
   */
  static async isAdmin(email) {
    if (!validarTipo(email, 'string')) return false;
    const convites = await Requisicoes.getIsAdmin(email);
    return convites;
  }

  /**
   * listarCriancas
   *
   * @returns
   * @memberof Usuario
   */
  static async listarCriancas() {
    const resp = await Requisicoes.getListarCriancas();
    return resp;
  }

  /**
   * consultarCrianca
   *
   * @returns
   * @param {string|number} idCrianca
   * @memberof Usuario
   */
  static async consultarCrianca(idCrianca) {
    if (!validarTipo(idCrianca, 'string') && !validarTipo(idCrianca, 'number')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.getConsultarCrianca(idCrianca);
    return resp;
  }

  /**
   * adicionarCrianca
   *
   * @param {object} crianca
   * @returns
   * @memberof Usuario
   */
  static async adicionarCrianca(crianca) {
    if (!validarTipo(crianca, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.postAdicionarCrianca(crianca);
    return resp;
  }

  /**
   * listarGrausAfinidade
   *
   * @returns
   * @memberof Usuario
   */
  static async listarGrausAfinidade() {
    const resp = await Requisicoes.getListarGrausAfinidade();
    return resp;
  }

  /**
   * consultarConteudo
   *
   * @returns
   * @param {string|number} idCrianca
   * @param {string} codigoPrograma
   * @memberof Usuario
   */
  static async consultarConteudo(idCrianca, codigoPrograma) {
    if (!validarTipo(idCrianca, 'string') && !validarTipo(idCrianca, 'number')) return { erro: 'Informações inválidas.' };
    if (!validarTipo(idCrianca, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.getConsultarConteudo(idCrianca, codigoPrograma);
    return resp;
  }
}
