import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AdminPlayer, Carregando, Confirmacao, FormItem } from '../../components';
import { modelItem } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const { query } = props;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false, onConfirm: () => {} });
  const [atualizar, setAtualizar] = useState(0);
  const [edicao, setEdicao] = useState(false);
  const [item, setItem] = useState({});
  const [fileAudio, setFileAudio] = useState(null);
  const [fileImagem, setFileImagem] = useState(null);
  const [fileNameAudio, setFileNameAudio] = useState('');
  const [fileNameImagem, setFileNameImagem] = useState('');

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', show: false, onConfirm: () => {} });
  };

  const cancelarEdicao = () => setEdicao(false);

  const editarItem = async values => {
    const { tipo, categoria, texto } = values;

    setCarregando(true);

    try {
      const changingItem = new FormData();
      changingItem.append('id', item.id);
      changingItem.append('audio', fileAudio);
      changingItem.append('audioNome', fileNameAudio);
      changingItem.append('imagem', fileImagem);
      changingItem.append('imagemNome', fileNameImagem);
      changingItem.append('idioma', 'pt-br');
      changingItem.append('tipo', tipo);
      changingItem.append('categoria', categoria);
      changingItem.append('texto', texto);

      const resp = await Admin.alterarItem(changingItem);
      if (resp === true) {
        setEdicao(false);
        setAtualizar(atualizar + 1);
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a alteração do item. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  const excluirItem = async () => {
    const { id } = item;

    fecharConfirmacao();
    setCarregando(true);

    try {
      const resp = await Admin.excluirItem(`${id}`);
      if (resp === true) {
        history.push('/admin/dados?aba=acervo');
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a exclusão do item. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  // Controle do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      const resp = await Admin.consultarItemPorId(query.get('idItem'));
      console.log('resp', resp);
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar o item.');
      } else if (resp.id) {
        setItem(modelItem(resp));
      }
      setCarregando(false);
    };
    inicializar();
  }, [query, atualizar]);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      {confirmacao.show ? (
        <Confirmacao mensagem={confirmacao.mensagem} show={confirmacao.show} onConfirm={confirmacao.onConfirm} onCancel={fecharConfirmacao} />
      ) : null}
      <Card className="mt-2">
        <Card.Header>
          <Card.Title className="m-0">Item</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-4">{`${item.nome || ''}`}</Card.Title>
          {edicao === true ? (
            <FormItem
              novo={false}
              onSubmit={editarItem}
              onCancel={cancelarEdicao}
              defaults={item}
              setFileAudio={setFileAudio}
              setFileNameAudio={setFileNameAudio}
              setFileImagem={setFileImagem}
              setFileNameImagem={setFileNameImagem}
            />
          ) : (
            <>
              <Row>
                <Col>
                  <Card.Text>
                    Idioma: <strong>{`${item.idiomaLabel || ''}`}</strong>
                  </Card.Text>
                </Col>
              </Row>
              <Row className="my-4">
                <Col>
                  <Card.Text>
                    Tipo: <strong>{`${item.tipo || ''}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Categoria: <strong>{`${item.categoria || ''}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Texto: <strong>{`${item.texto || ''}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Imagem: <strong>{`${item.imagemNome || ''}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Áudio: <AdminPlayer idItem={query.get('idItem')} />
                  </Card.Text>
                </Col>
              </Row>
              {/* <Accordion defaultActiveKey="0" className="mt-4">
            <Card className="border-0">
              <Accordion.Toggle as={Card.Header} eventKey="0">
                Crianças
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {grupo.criancas &&
                    grupo.criancas.length > 0 &&
                    grupo.criancas.map(c => (
                      <Row className="mb-2" key={`${c.id}`}>
                        <Col>{`${c.nome || ''}`}</Col>
                        <Col>{`${c.idade} anos`}</Col>
                        <Col>{`${c.grauAfinidade || ''}`}</Col>
                        <Col className="d-flex flex-row">
                          Matriculado(a)?
                          <Form.Check
                            checked={c.matriculada}
                            type="checkbox"
                            onChange={() => alterarStatusMatricula(`${c.id}`, !c.matriculada)}
                            className="ml-2"
                          />
                        </Col>
                        <Col>{`${c.statusLabel || ''}`}</Col>
                        <Col className="d-flex flex-row justify-content-end">
                          <Button variant="outline-danger" className="mx-2" size="sm" onClick={() => excluirCrianca(`${c.id}`)}>
                            X
                          </Button>
                        </Col>
                      </Row>
                    ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion> */}
              <Row className="mt-4 d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=acervo')}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="info" onClick={() => setEdicao(true)}>
                  Editar
                </Button>
                <Button
                  className="mx-2"
                  variant="danger"
                  onClick={() =>
                    setConfirmacao({
                      mensagem: 'Tem certeza que deseja excluir o item? Esta operação não pode ser desfeita.',
                      show: true,
                      onConfirm: excluirItem,
                    })
                  }
                >
                  Excluir
                </Button>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
