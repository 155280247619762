import React, { useEffect, useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Cabecalho, Carregando, Voltar } from '../../components';
import { modelCrianca } from '../../models';
import { Usuario } from '../../services';
import { alertar, getQuery } from '../../utils';

export default () => {
  const location = useLocation();
  const [carregando, setCarregando] = useState(true);
  const [crianca, setCrianca] = useState({});

  useEffect(() => {
    const inicializar = async () => {
      const query = getQuery(location);
      const idCrianca = query.get('idCrianca');
      const consulta = await Usuario.consultarCrianca(idCrianca);
      if (consulta.erro) {
        alertar('error', consulta.erro || 'Erro ao consultar o cadastro da criança.');
      } else if (consulta.id) {
        setCrianca(modelCrianca(consulta));
      }
      setCarregando(false);
    };
    inicializar();
  }, [location]);

  return (
    <>
      <Cabecalho location={location} />
      <Container fluid className="h-100">
        <Carregando show={carregando} />
        <br />
        <Voltar />
        {console.log('crianca', crianca)}
        {crianca.id && (
          <Card>
            <Card.Header>
              <Card.Title>{crianca.nome}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Selecione um programa:</Card.Text>
              {Array.isArray(crianca.programas) &&
                crianca.programas.map(p => (
                  <Button
                    key={`${crianca.id}`}
                    className="mx-4 my-4 p-4"
                    href={`/opcoes-apresentacao?idCrianca=${crianca.id}&codigoPrograma=${p.codigo}`}
                  >
                    {p.nome || 'Sem nome'}
                  </Button>
                ))}
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};
