import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  return (
    <Card className="border-0">
      <Card.Body>
        <Card.Title className="text-primary text-center ">Dashboards</Card.Title>
        <Button onClick={() => history.goBack()}>Voltar</Button>
      </Card.Body>
    </Card>
  );
};
