import React, { useState } from 'react';
import { Container, Card, Form, Button, Image, Row, Col } from 'react-bootstrap';
import { withRouter, Redirect, useHistory } from 'react-router-dom';
import { Carregando } from '../../components';
import { Auth } from '../../services';
import { alertar, validarEmail } from '../../utils';

const logo = require('../../assets/logo/logo.png');

export default withRouter(props => {
  const [carregando, setCarregando] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [validatedLogin, setValidatedLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const history = useHistory();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) login();
    setValidatedLogin(true);
  };

  const login = async () => {
    setCarregando(true);
    try {
      if (!validarEmail(email)) {
        setValidatedLogin(false);
        return alertar('error', 'Email inválido.');
      }

      // Login
      const loggedIn = await Auth.conectarComEmailESenha(email, senha);
      if (loggedIn === true) {
        const auth = new Auth();
        auth.setAuth(true);
        setRedirect(true);
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setValidatedLogin(false);
      setCarregando(false);
    }
  };

  if (redirect) return <Redirect to="/" />;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
      <Container style={{ minWidth: 300 }}>
        <Carregando show={carregando} />
        <br />
        <Card className="text-center" xs="auto">
          <Card.Body>
            <Image src={logo} style={{ height: 100, marginBottom: 20 }} />
            <Card.Title style={{ marginBottom: 60 }}>LOGIN</Card.Title>
            <Form noValidate validated={validatedLogin} onSubmit={handleSubmit}>
              <Form.Group xs={4} controlId="email" className="">
                <Form.Control required type="text" placeholder="Email" value={email} onChange={event => setEmail(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Email inválido'}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group xs={4} controlId="senha" className="">
                <Form.Control required type="password" placeholder="Senha" value={senha} onChange={event => setSenha(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Digite sua senha'}</Form.Control.Feedback>
              </Form.Group>

              <Button className="mx-2" variant="outline-primary" onClick={() => history.goBack()}>
                Voltar
              </Button>
              <Button className="mx-2" variant="primary" type="submit">
                Entrar
              </Button>
            </Form>
            <Row className="mt-4">
              <Col>
                <Button variant="link" onClick={() => history.push('/esqueci')}>
                  Esqueci a senha
                </Button>
              </Col>
              <Col>
                <Button variant="link" onClick={() => history.push('/cadastro')}>
                  Quero me cadastrar
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
});
