import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Container, Card, Row, Button, OverlayTrigger, Tooltip, Accordion, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { AccordionLineToggle, Cabecalho, Carregando, Voltar } from '../../components';
import { Usuario } from '../../services';
import { alertar, getQuery } from '../../utils';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const query = getQuery(location);
  const idCrianca = query.get('idCrianca');
  const codigoPrograma = query.get('codigoPrograma');
  const [isAdmin, setIsAdmin] = useState(true);
  const [carregando, setCarregando] = useState(true);
  const [conteudo, setConteudo] = useState({});
  const cookieOpcoes = {
    audio: 'C',
    imagem: 'C',
    ...JSON.parse(Cookies.get(`opcoes.${idCrianca}.${codigoPrograma}`) || '{ "audio": "C", "imagem": "C" }'),
  };
  const [opcoes, setOpcoes] = useState(cookieOpcoes);

  useEffect(() => {
    const inicializar = async () => {
      const consulta = await Usuario.consultarConteudo(idCrianca, codigoPrograma);
      console.log('consulta', consulta);
      if (consulta.erro) {
        alertar('error', consulta.erro || 'Erro ao consultar conteúdo.');
      } else if (consulta.codigoPrograma) {
        setConteudo(consulta);
      }
      setCarregando(false);
    };
    inicializar();
  }, [idCrianca, codigoPrograma]);

  return (
    <>
      <Cabecalho location={location} />
      <Container fluid className="h-100">
        <Carregando show={carregando} />
        <br />
        <Voltar to={`/selecao-programa?idCrianca=${idCrianca}`} />
        {conteudo.codigoPrograma && (
          <Card>
            <Card.Header>
              <Row className="mx-1 d-flex justify-content-between">
                <Card.Title>{conteudo.nomeCrianca || 'Erro ao obter nome da criança'}</Card.Title>
                <Card.Title>Programa {conteudo.nomePrograma || 'Nome não encontrado'}</Card.Title>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mx-1 d-flex justify-content-end">
                <Button
                  className="m-1"
                  variant="info"
                  size="sm"
                  onClick={() => history.push(`/configuracoes?idCrianca=${idCrianca}&codigoPrograma=${codigoPrograma}`)}
                >
                  Configurações
                </Button>
                <OverlayTrigger
                  placement="bottom"
                  overlay={props => (
                    <Tooltip id={`tooltip-bottom`} ref={props.ref} {...props}>
                      Em desenvolvimento
                    </Tooltip>
                  )}
                >
                  {({ ref, ...triggerHandler }) => (
                    <Button className="m-1" variant="secondary" size="sm" ref={ref} {...triggerHandler}>
                      Registrar desempenho
                    </Button>
                  )}
                </OverlayTrigger>
              </Row>
              <Row className="mx-1">
                <Card.Title className="mr-2">Informações sobre o programa:</Card.Title>
                <Card.Text>{conteudo.descricao || 'Descrição não encontrada.'}</Card.Text>
              </Row>
              <br />
              <Row className="mx-1">
                <Card.Title className="mr-2">Conteúdo de hoje:</Card.Title>
                <Card.Text>Dia {conteudo.atividades.diaAtual || 'não definido'}</Card.Text>
              </Row>
              {isAdmin && (
                <>
                  <br />
                  <Row className="mx-1">
                    <Card.Title className="mr-2">Modo Administrador:</Card.Title>
                    <Form.Check checked type="checkbox" onChange={() => {}} className="ml-2" />
                    Ativado
                  </Row>
                </>
              )}
              <br />
              <Row className="mx-1">
                <Card.Title className="mr-2">Opções de Apresentacao:</Card.Title>
                <label className="mr-4">{opcoes.audio === 'C' ? 'Com' : 'Sem'} áudio</label>
                <label className="mr-4">|</label>
                <label className="mr-4">{opcoes.imagem === 'C' ? 'Com' : 'Sem'} imagem</label>
              </Row>
              <Row className="mx-1 d-flex justify-content-center">
                <Button variant="success" onClick={() => history.push('/apresentacao', { conteudo, opcoes, idCrianca, codigoPrograma })}>
                  Iniciar
                </Button>
              </Row>
              <br />
              <Accordion defaultActiveKey="0" className="mt-4 mb-2">
                <Card className="border-0">
                  <AccordionLineToggle eventKey="0">Conteúdos anteriores</AccordionLineToggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Card.Text>Em desenvolvimento</Card.Text>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};
