import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import { Button, Card, Container } from 'react-bootstrap';
import { Redirect, Route, useHistory } from 'react-router-dom';

const Home = () => {
  const history = useHistory();

  return (
    <Container fluid className="m-0 p-0">
      <Card className="vw-100 vh-100 d-flex text-center m-0 p-0 border-0 rounded-0 overflow-hidden">
        <Card.Img src="fundo-home.jpg" className="rounded-0 vh-100" />
        <Card.ImgOverlay className="d-flex flex-column justify-content-center">
          <Jumbotron
            className="text-black align-center d-flex flex-column align-items-center justify-content-center"
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
          >
            <Card.Title as="h1" className="text-light fs-6 fw-bold">
              SEJA BEM-VINDO À SMART BABY ONLINE!
            </Card.Title>
            <Button variant="primary" size="lg" className="mt-5 shadow" onClick={() => history.push('/login')}>
              ENTRAR
            </Button>
          </Jumbotron>
        </Card.ImgOverlay>
      </Card>
    </Container>
  );
};

const HomePage = ({ authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (authed === true ? <Redirect to={{ pathname: 'inicio', state: { from: props.location } }} /> : <Home {...props} />)}
    />
  );
};

export default HomePage;
