import moment from 'moment';

/**
 * @method modelItem
 * @description Modelo padrão de objeto da classe
 * @param {string} id - id do grupo
 * @param {string} idioma - idioma
 * @param {string} idiomaLAbel - idioma formatado
 * @param {string} tipo - tipo
 * @param {string} categoria - categoria
 * @param {string} texto - texto
 * @param {string} imagemNome - imagemNome
 * @param {string} imagemFormato - imagemFormato
 * @param {string} imagemMd5 - imagemMd5
 * @param {string} imagem - imagem
 * @param {string} audioNome - audioNome
 * @param {string} audioFormato - audioFormato
 * @param {string} audioMd5 - audioMd5
 * @param {string} audio - audio
 * @param {boolean} apresentarTexto - apresentarTexto
 * @param {boolean} apresentarImagem - apresentarImagem
 * @param {string} status - status
 * @param {string} statusLabel - status formatado
 * @param {timestamp} timestamp - momento do registro
 * @returns {object} Objeto formatado
 */
export default ({
  id,
  idioma = '',
  tipo = '',
  categoria = '',
  texto = '',
  imagem_nome = '',
  imagem_formato = '',
  imagem_md5 = '',
  imagem = '',
  audio_nome = '',
  audio_formato = '',
  audio_md5 = '',
  audio = '',
  apresentar_texto = '',
  apresentar_imagem = '',
  status = '',
  timestamp = '',
}) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'I') return 'INATIVO';
    if (status === 'S') return 'SUSPENSO';
    if (status === 'C') return 'CANCELADO';
    return 'INVÁLIDO';
  };
  const getIdiomaLabel = () => {
    if (idioma === 'pt-br') return 'Português';
    if (idioma === 'en') return 'Inglês';
    if (idioma === 'es') return 'Espanhol';
    return 'Não definido';
  };
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);

  return {
    id,
    idioma,
    idiomaLabel: getIdiomaLabel(),
    tipo,
    categoria,
    texto,
    imagemNome: imagem_nome,
    imagemFormato: imagem_formato,
    imagemMd5: imagem_md5,
    imagem,
    audioNome: audio_nome,
    audioFormato: audio_formato,
    audioMd5: audio_md5,
    audio,
    apresentarTexto: apresentar_texto,
    apresentarImagem: apresentar_imagem,
    status,
    statusLabel: getStatusLabel(),
    timestamp: getDataHora(timestamp),
  };
};
