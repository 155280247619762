import React, { useEffect } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export default props => {
  const {
    resets,
    novo = true,
    onSubmit = () => {},
    onCancel = () => {},
    setFileAudio = () => {},
    setFileNameAudio = () => {},
    setFileImagem = () => {},
    setFileNameImagem = () => {},
    defaults = { idioma: '', tipo: '', categoria: '', texto: '', imagem: null, audio: null },
  } = props;
  const history = useHistory();
  const { handleSubmit, register, reset, errors, control } = useForm();

  const onReset = () => {
    reset();
    setFileAudio(null);
    setFileNameAudio(null);
    setFileImagem(null);
    setFileNameImagem(null);
  };

  useEffect(() => {
    const inicializar = async () => {
      if (resets) {
        reset(resets, {
          errors: false, // errors will not be reset
          dirtyFields: false, // dirtyFields will not be reset
          isDirty: false, // dirty will not be reset
          isSubmitted: false,
          touched: false,
          isValid: false,
          submitCount: false,
        });
        setFileAudio(null);
        setFileNameAudio(null);
        setFileImagem(null);
        setFileNameImagem(null);
        document.getElementById('texto').focus();
      }
    };
    inicializar();
  }, [resets, setFileAudio, setFileNameAudio, setFileImagem, setFileNameImagem, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
      <Form.Row className="justify-content-between mt-2">
        {/* tipo */}
        <Form.Group as={Col} sm="auto" controlId="tipo" className="align-middle mb-0 pr-4">
          <Form.Label>Tipo</Form.Label>
          <Form.Control
            name="tipo"
            type="text"
            maxLength={100}
            defaultValue={defaults.tipo}
            ref={register({ required: 'É necessário preencher o tipo do item.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.tipo && errors.tipo.message}
          </Col>
        </Form.Group>
        {/* categoria */}
        <Form.Group as={Col} sm="auto" controlId="categoria" className="align-middle mb-0 pr-4">
          <Form.Label>Categoria</Form.Label>
          <Form.Control
            name="categoria"
            type="text"
            maxLength={100}
            defaultValue={defaults.categoria}
            ref={register({ required: 'É necessário preencher a categoria do item.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.categoria && errors.categoria.message}
          </Col>
        </Form.Group>
        {/* texto */}
        <Form.Group as={Col} sm="auto" controlId="texto" className="align-middle mb-0 pr-4">
          <Form.Label>Texto</Form.Label>
          <Form.Control
            name="texto"
            type="text"
            maxLength={100}
            defaultValue={defaults.texto}
            ref={register({ required: 'É necessário preencher o texto do item.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.texto && errors.texto.message}
          </Col>
        </Form.Group>
        <Col>
          {/* Imagem */}
          <Form.Group as={Col} sm="auto" controlId="imagem" className="align-middle mb-0 px-4 border-dashed">
            <Form.Label>Imagem</Form.Label>
            <Controller
              control={control}
              name="imagem"
              defaultValue={defaults.imagemNome}
              render={dateprops => (
                <input
                  type="file"
                  accept=".jpg,.jpeg,image/jpeg"
                  className="custom-file mt-2"
                  inputRef={dateprops.ref}
                  onChange={e => {
                    setFileImagem(e.target.files[0]);
                    setFileNameImagem(e.target.files[0].name);
                    dateprops.onChange(e);
                  }}
                />
              )}
            />
            <Col as={Card.Text} className="text-center">
              <i className="fa fa-upload fa-lg mr-2 text-secondary" alt="Arraste o arquivo para esta área." />
              Clique no botão ou arraste o arquivo para esta área.
            </Col>
            <Col as={Card.Text} className="text-danger">
              {errors.imagem && errors.imagem.message}
            </Col>
          </Form.Group>
          <br />
          <Form.Group as={Col} sm="auto" controlId="audio" className="align-middle mb-0 px-4 border-dashed">
            <Form.Label>Áudio</Form.Label>
            <Controller
              control={control}
              name="audio"
              defaultValue={defaults.audioNome}
              render={dateprops => (
                <input
                  type="file"
                  accept=".ogg,audio/ogg,video/ogg"
                  className="custom-file mt-2"
                  inputRef={dateprops.ref}
                  onChange={e => {
                    setFileAudio(e.target.files[0]);
                    setFileNameAudio(e.target.files[0].name);
                    dateprops.onChange(e);
                  }}
                />
              )}
            />
            <Col as={Card.Text} className="text-center">
              <i className="fa fa-upload fa-lg mr-2 text-secondary" alt="Arraste o arquivo para esta área." />
              Clique no botão ou arraste o arquivo para esta área.
            </Col>
            <Col as={Card.Text} className="text-danger">
              {errors.audio && errors.audio.message}
            </Col>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className="justify-content-center mt-4">
        <Col xs="auto">
          {novo === true ? (
            <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=acervo')}>
              Voltar
            </Button>
          ) : (
            <Button className="mx-2" variant="danger" onClick={() => onCancel()}>
              Cancelar
            </Button>
          )}
          <Button className="mx-2" variant="outline-dark" onClick={() => onReset()}>
            Limpar
          </Button>
          <Button className="mx-2" variant="success" type="submit">
            Salvar
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};
