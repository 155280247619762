import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Carregando, Confirmacao, FormGrupo, ListaItens } from '../../components';

import { modelGrupo } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const { query } = props;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false, onConfirm: () => {} });
  const [atualizar, setAtualizar] = useState(0);
  const [showLista, setShowLista] = useState(false);
  const [edicao, setEdicao] = useState(false);
  const [grupo, setGrupo] = useState({});

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', show: false, onConfirm: () => {} });
  };

  const cancelarEdicao = () => setEdicao(false);

  const editarGrupo = async values => {
    const { nome, nivel } = values;

    setCarregando(true);

    try {
      const changingGrupo = { codigo: grupo.codigo, nome, nivel };

      const resp = await Admin.alterarGrupo(changingGrupo);
      if (resp === true) {
        setEdicao(false);
        setAtualizar(atualizar + 1);
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a alteração do grupo. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  const vincularItem = async (idItem, ordem, acao) => {
    try {
      fecharConfirmacao();
      setShowLista(false);
      setCarregando(true);
      if (!ordem >= 1 && !ordem <= 9999) return alertar('warning', 'Ordem deve ser entre 1 e 9999');
      const vinculacao = await Admin.vincularItemGrupo({
        tipoVinculo: acao,
        codigoGrupo: grupo.codigo,
        idItem,
        ordem: parseInt(ordem, 10),
      });
      if (vinculacao.erro) {
        alertar('error', vinculacao.erro);
      }
      if (vinculacao === true) {
        setAtualizar(atualizar + 1);
        if (acao === 'I') alertar('success', 'Item vinculado com sucesso.');
        else if (acao === 'E') alertar('success', 'Item desvinculado com sucesso.');
      }
    } catch (error) {
      alertar('error', 'Erro ao tentar vinvular o item ao grupo.');
    } finally {
      setCarregando(false);
    }
  };

  const excluirGrupo = async () => {
    const { codigo } = grupo;

    fecharConfirmacao();
    setCarregando(true);

    try {
      const resp = await Admin.excluirGrupo(codigo);
      if (resp === true) {
        history.push('/admin/dados?aba=grupos');
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a exclusão do grupo. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  // Controle do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      const resp = await Admin.consultarGrupoPorCodigo(query.get('codigo'));
      console.log('resp', resp);
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar o grupo.');
      } else if (resp.codigo) {
        setGrupo(modelGrupo(resp));
      }
      setCarregando(false);
    };
    inicializar();
  }, [query, atualizar]);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      {confirmacao.show ? (
        <Confirmacao mensagem={confirmacao.mensagem} show={confirmacao.show} onConfirm={confirmacao.onConfirm} onCancel={fecharConfirmacao} />
      ) : null}
      {showLista && <ListaItens show={showLista} onAdd={vincularItem} onCancel={() => setShowLista(false)} />}
      <Card className="mt-2">
        <Card.Header>
          <Card.Title className="m-0">Grupo</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-4">{`${grupo.nome || ''}`}</Card.Title>
          {edicao === true ? (
            <FormGrupo novo={false} onSubmit={editarGrupo} onCancel={cancelarEdicao} defaults={grupo} />
          ) : (
            <>
              <Row>
                <Col>
                  <Card.Text>
                    Código: <strong>{`${grupo.codigo || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Nome: <strong>{`${grupo.nome || ''}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Nível: <strong>{`${grupo.nivel || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Status: <strong>{`${grupo.statusLabel || ''}`}</strong>
                  </Card.Text>
                </Col>
              </Row>
              <Row className="mt-4 d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=grupos')}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="info" onClick={() => setEdicao(true)}>
                  Editar
                </Button>
                <Button
                  className="mx-2"
                  variant="danger"
                  onClick={() =>
                    setConfirmacao({
                      mensagem: 'Tem certeza que deseja excluir o grupo? Esta operação não pode ser desfeita.',
                      show: true,
                      onConfirm: excluirGrupo,
                    })
                  }
                >
                  Excluir
                </Button>
                <Button className="mx-2" variant="success" onClick={() => setShowLista(true)}>
                  Adicionar Item
                </Button>
              </Row>
              {grupo.itens && (
                <>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <Card.Title className="mb-4">Grupos vinculados</Card.Title>
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th className="text-center">Idioma</th>
                            <th className="text-center">Tipo</th>
                            <th className="text-center">Categoria</th>
                            <th className="text-center">Texto</th>
                            <th className="text-center">Ordem</th>
                            <th className="text-center"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {grupo.itens.map(i => (
                            <tr key={`${i.idItem}`}>
                              <td className="text-center align-middle">{i.idioma}</td>
                              <td className="text-center align-middle">{i.tipo}</td>
                              <td className="text-center align-middle">{i.categoria}</td>
                              <td className="text-center align-middle">{i.texto}</td>
                              <td className="text-center align-middle">{i.ordem}</td>
                              <td className="text-center align-middle">
                                <Button
                                  variant="success"
                                  onClick={() =>
                                    setConfirmacao({
                                      mensagem: 'Tem certeza que deseja desvincular o item do grupo?',
                                      show: true,
                                      onConfirm: () => vincularItem(i.idItem, i.ordem, 'E'),
                                    })
                                  }
                                >
                                  Excluir
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
