import React from 'react';
import { Button, Card, Col, Form, Accordion } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AccordionLineToggle } from '.';

const WatchQtdeMaxSessoes = ({ control, defaults = {} }) => {
  const qtde = useWatch({ control, name: 'qtdeMaxSessoes', defaultValue: defaults.qtdeMaxSessoes || 0 });
  return <div>{qtde}</div>;
};

export default props => {
  const {
    novo = true,
    onSubmit = () => {},
    onCancel = () => {},
    defaults = { nome: '', descricao: '', qtdeMaxSessoes: 0, qtdeRepeticoesSessao: 0, visibilidade: 'E', observacao: '', dias: [] },
  } = props;
  const { handleSubmit, register, control, errors } = useForm();
  const history = useHistory();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
      <Form.Row className="justify-content-between mt-0">
        {/* Código */}
        {novo === true ? (
          <Form.Group as={Col} sm="auto" controlId="codigo" className="align-middle mb-0 pr-4">
            <Form.Label>Código</Form.Label>
            <Form.Control name="codigo" type="text" maxLength={100} ref={register({ required: 'É necessário preencher o código do programa.' })} />
            <Col as={Card.Text} className="text-danger">
              {errors.codigo && errors.codigo.message}
            </Col>
          </Form.Group>
        ) : null}
        {/* Nome */}
        <Form.Group as={Col} controlId="nome" className={`align-middle mb-0 ${novo ? 'px-4' : 'pr-4'}`}>
          <Form.Label>Nome do Programa</Form.Label>
          <Form.Control
            name="nome"
            type="text"
            maxLength={290}
            defaultValue={defaults.nome}
            ref={register({ required: 'É necessário preencher um nome para o programa.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.nome && errors.nome.message}
          </Col>
        </Form.Group>
        {/* visibilidade */}
        <Form.Group as={Col} sm="auto" controlId="visibilidade" className="align-middle mb-0 px-4">
          <Form.Label>Visibilidade</Form.Label>
          <Form.Control
            as="select"
            name="visibilidade"
            defaultValue={defaults.visibilidade}
            ref={register({ required: 'É necessário selecionar uma visibilidade para o programa.' })}
          >
            <option value={''}>Selecione...</option>
            <option value="E">EM EDIÇÃO</option>
            <option value="P">PÚBLICO</option>
            <option value="A">ADMIN</option>
          </Form.Control>
          <Col as={Card.Text} className="text-danger">
            {errors.visibilidade && errors.visibilidade.message}
          </Col>
        </Form.Group>
      </Form.Row>
      <br />
      <Form.Row className="justify-content-between mt-0">
        {/* descricao */}
        <Form.Group as={Col} controlId="descricao" className="align-middle mb-0 pr-4">
          <Form.Label>Descrição do Programa</Form.Label>
          <Form.Control name="descricao" type="text" maxLength={400} defaultValue={defaults.descricao} ref={register({})} />
          <Col as={Card.Text} className="text-danger">
            {errors.descricao && errors.descricao.message}
          </Col>
        </Form.Group>
      </Form.Row>
      <br />
      <Form.Row className="justify-content-between mt-0">
        {/* qtdeMaxSessoes */}
        <Form.Group as={Col} controlId="qtdeMaxSessoes" className="align-middle mb-0 pr-4">
          <Form.Label>Quantidade máxima de sessões</Form.Label>
          <Form.Control
            name="qtdeMaxSessoes"
            type="text"
            maxLength={4}
            defaultValue={defaults.qtdeMaxSessoes}
            ref={register({ required: 'É necessário preencher a quantidade máxima de sessões para o programa.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.qtdeMaxSessoes && errors.qtdeMaxSessoes.message}
          </Col>
        </Form.Group>
        {/* qtdeRepeticoesSessao */}
        <Form.Group as={Col} controlId="qtdeRepeticoesSessao" className="align-middle mb-0 px-4">
          <Form.Label>Quantidade de repetição de cada sessão</Form.Label>
          <Form.Control
            name="qtdeRepeticoesSessao"
            type="text"
            maxLength={4}
            defaultValue={defaults.qtdeRepeticoesSessao}
            ref={register({ required: 'É necessário preencher a uantidade de repetição de cada sessão para o programa.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.qtdeRepeticoesSessao && errors.qtdeRepeticoesSessao.message}
          </Col>
        </Form.Group>
      </Form.Row>
      <br />
      <Form.Row className="justify-content-between mt-0">
        {/* observacao */}
        <Form.Group as={Col} controlId="observacao" className="align-middle mb-0 pr-4">
          <Form.Label>Observações:</Form.Label>
          <Form.Control name="observacao" type="text" maxLength={1000} defaultValue={defaults.observacao} ref={register({})} />
          <Col as={Card.Text} className="text-danger">
            {errors.observacao && errors.observacao.message}
          </Col>
        </Form.Group>
      </Form.Row>
      <br />
      <Form.Row className="justify-content-center mt-2">
        <Col xs="auto">
          {novo === true ? (
            <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=programas')}>
              Voltar
            </Button>
          ) : (
            <Button className="mx-2" variant="danger" onClick={() => onCancel()}>
              Cancelar
            </Button>
          )}
          <Button className="mx-2" variant="outline-dark" type="reset">
            Limpar
          </Button>
          <Button className="mx-2" variant="success" type="submit">
            Salvar
          </Button>
        </Col>
      </Form.Row>
      <Form.Row className="justify-content-between mt-2">
        {/* dias */}
        <Form.Group as={Col} controlId="dias" className="align-middle mb-0 pr-4">
          <Col as={Card.Text} className="text-danger">
            {errors.dias && errors.dias.message}
          </Col>
          <Accordion defaultActiveKey="0" className="mt-4 mb-2">
            <Card className="border-0">
              <AccordionLineToggle eventKey="0">Grupos</AccordionLineToggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <WatchQtdeMaxSessoes control={control} qtdeDefault={defaults.qtdeMaxSessoes} />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
