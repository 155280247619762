import axios from 'axios';

import { isDomesticServer, validarTipo } from '../utils';
import { api } from '../constants';
import { Auth } from '.';

export default class Requisicoes {
  /**
   * Cria uma instância singleton da classe Requisicoes.
   * Esta implementação é necessária para a utilização correta dos tokens no momento exato de sua alteração.
   * @memberof Requisicoes
   */
  constructor() {
    if (!Requisicoes.instance) {
      Requisicoes.instance = this;
    }

    return Requisicoes.instance;
  }
  /**
   * configureAxios
   *
   * @static
   * @param {string} csrfToken
   * @memberof Requisicoes
   */
  configureAxios(csrfToken) {
    return new Promise((resolve, reject) => {
      try {
        // remove qualquer interceptor que existe previamente
        if (this.intercept !== undefined) {
          axios.interceptors.request.eject(this.intercept);
        }
        // cria um novo interceptor e registra seu id no atributo intercept
        this.intercept = axios.interceptors.request.use(
          req => {
            // Não entrega o token caso o servidor não seja doméstico
            if (isDomesticServer(req.url)) {
              req.headers['XSRF-TOKEN'] = `${csrfToken}`;
            }
            return req;
          },
          error => {
            throw error;
          },
        );
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * releaseAxios
   *
   * @memberof Requisicoes
   */
  releaseAxios() {
    if (this.intercept !== undefined) {
      axios.interceptors.request.eject(this.intercept);
    }
  }

  /**
   * requisitar
   *
   * @static
   * @param {string} type    - verbo da requisição [GET, POST, PUT...]
   * @param {string} URI     - URI completo do endpoint
   * @param {object} body    - corpo da requisição
   * @memberof Requisicoes
   */
  static async requisitar(type, URI, body = {}) {
    try {
      if (!validarTipo(type, 'string') || !validarTipo(URI, 'string') || !validarTipo(body, 'object')) return false;
      const raw = await axios({
        method: type.toLowerCase(),
        url: URI,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        withCredentials: true,
        xsrfCookieName: 'XSRF-TOKEN',
        data: type.toLowerCase() === 'get' ? null : body,
        validateStatus: status =>
          (status >= 200 && status < 300) ||
          status === 302 ||
          status === 304 ||
          status === 401 ||
          status === 403 ||
          status === 409 ||
          status === 412,
      });
      if (raw.status === 401) {
        const auth = new Auth();
        auth.setAuth(false);
      }
      return raw.data;
    } catch (error) {
      console.warn('Erro na requisição:', error);
      const erro = { httpStatusCode: 500, data: `Não foi possível estabelecer conexão com o servidor. ${error}` };
      return erro;
    }
  }

  /**
   * tratarResposta
   *
   * @static
   * @param {*} response
   * @param {*} url
   * @memberof Requisicoes
   */
  static tratarResposta(response, url) {
    try {
      if (!response) return { erro: 'Erro ao receber resposta do servidor.' };
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200) return data;
      if (httpStatusCode === 401 || httpStatusCode === 403 || httpStatusCode === 409)
        return { erro: 'Usuário desconectado. Realize o login novamente.' };
      if (httpStatusCode === 412 || httpStatusCode === 428) return { erro: `${data}` };
      if (httpStatusCode >= 500) return { erro: `${data}. URL: ${url}` };
      return { erro: `Erro desconhecido ao se comunicar com o servidor. URL: ${url}`, data };
    } catch (error) {
      return { erro: `Erro de comunicação. ${error.message ? error.message.toString().substring(0, 50).concat('... ') : ''}URL: ${url}` };
    }
  }

  /**
   * getTEste
   *
   * @static
   * @param {string}
   * @memberof Requisicoes
   */
  static async getTEste() {
    const servico = api.TESTE;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getCsrftoken
   *
   * @static
   * @param {string}
   * @memberof Requisicoes
   */
  static async getCsrftoken() {
    const servico = api.CSRFTOKEN;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postSessionLogin
   *
   * @static
   * @param {string} idToken
   * @param {string} csrftoken
   * @memberof Requisicoes
   */
  static async postSessionLogin(idToken, csrftoken) {
    const servico = api.SESSION_LOGIN;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, { idToken, 'XSRF-TOKEN': csrftoken });
    return this.tratarResposta(response, url);
  }

  /**
   * getSessionLogout
   *
   * @static
   * @memberof Requisicoes
   */
  static async getSessionLogout() {
    const servico = api.SESSION_LOGOUT;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    if (response.status === 200) {
      const auth = new Auth();
      auth.setAuth(false);
    }
    return this.tratarResposta(response, url);
  }

  /**
   * postCadastroUsuario
   *
   * @static
   * @param {object} usuario
   * @memberof Requisicoes
   */
  static async postCadastroUsuario(usuario) {
    const servico = api.CADASTRO_USUARIO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, usuario);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarCriancas
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarCriancas() {
    const servico = api.USUARIO_LISTAR_CRIANCAS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarCrianca
   *
   * @static
   * @param {string|number} idCrianca
   * @memberof Requisicoes
   */
  static async getConsultarCrianca(idCrianca) {
    const servico = api.USUARIO_CONSULTAR_CRIANCA;
    const url = servico.url(idCrianca);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postAdicionarCrianca
   *
   * @static
   * @param {object} crianca
   * @memberof Requisicoes
   */
  static async postAdicionarCrianca(crianca) {
    const servico = api.USUARIO_ADICIONAR_CRIANCA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, crianca);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarConteudo
   *
   * @static
   * @param {string|number} idCrianca
   * @param {string} codigoPrograma
   * @memberof Requisicoes
   */
  static async getConsultarConteudo(idCrianca, codigoPrograma) {
    const servico = api.USUARIO_CONSULTAR_CONTEUDO;
    const url = servico.url(idCrianca, codigoPrograma);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarGrausAfinidade
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarGrausAfinidade() {
    const servico = api.USUARIO_LISTAR_GRAUS_AFINIDADE;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarUsuarios
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarUsuarios() {
    const servico = api.ADMIN_LISTAR_USUARIOS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarUsuario
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getConsultarUsuario(uid) {
    const servico = api.ADMIN_CONSULTAR_USUARIOS;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarStatusMatricula
   *
   * @static
   * @param {string} idCrianca
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarStatusMatricula(idCrianca, body) {
    const servico = api.ADMIN_ALTERAR_STATUS_MATRICULA;
    const url = servico.url(idCrianca);
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarStatusCrianca
   *
   * @static
   * @param {string} idCrianca
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarStatusCrianca(idCrianca, body) {
    const servico = api.ADMIN_ALTERAR_STATUS_CRIANCA;
    const url = servico.url(idCrianca);
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * postAdicionarCriancaAdm
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async postAdicionarCriancaAdm(body) {
    const servico = api.ADMIN_ADICIONAR_CRIANCA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarStatusUsuario
   *
   * @static
   * @param {string} AdmUsuarioDetalhe
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarStatusUsuario(uid, body) {
    const servico = api.ADMIN_ALTERAR_STATUS_USUARIO;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * postAdicionarGrupo
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async postAdicionarGrupo(body) {
    const servico = api.ADMIN_ADICIONAR_GRUPO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarGrupos
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarGrupos() {
    const servico = api.ADMIN_LISTAR_GRUPOS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarGrupoPorCodigo
   *
   * @static
   * @param {string} codigo
   * @memberof Requisicoes
   */
  static async getConsultarGrupoPorCodigo(codigo) {
    const servico = api.ADMIN_CONSULTAR_GRUPO;
    const url = servico.url(codigo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarGrupo
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarGrupo(body) {
    const servico = api.ADMIN_ALTERAR_GRUPO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * deleteExcluirGrupo
   *
   * @static
   * @param {string} codigo
   * @memberof Requisicoes
   */
  static async deleteExcluirGrupo(codigo) {
    const servico = api.ADMIN_EXCLUIR_GRUPO;
    const url = servico.url(codigo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postAdicionarPrograma
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async postAdicionarPrograma(body) {
    const servico = api.ADMIN_ADICIONAR_PROGRAMA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarProgramas
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarProgramas() {
    const servico = api.ADMIN_LISTAR_PROGRAMAS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarProgramaPorCodigo
   *
   * @static
   * @param {string} codigo
   * @memberof Requisicoes
   */
  static async getConsultarProgramaPorCodigo(codigo) {
    const servico = api.ADMIN_CONSULTAR_PROGRAMA;
    const url = servico.url(codigo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarPrograma
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarPrograma(body) {
    const servico = api.ADMIN_ALTERAR_PROGRAMA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * deleteExcluirPrograma
   *
   * @static
   * @param {string} codigo
   * @memberof Requisicoes
   */
  static async deleteExcluirPrograma(codigo) {
    const servico = api.ADMIN_EXCLUIR_PROGRAMA;
    const url = servico.url(codigo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchVincularGrupoPrograma
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchVincularGrupoPrograma(body) {
    const servico = api.ADMIN_VINC_GRUPO_PROGRAMA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchVincularItemGrupo
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchVincularItemGrupo(body) {
    const servico = api.ADMIN_VINC_ITEM_GRUPO;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * postAdicionarItem
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async postAdicionarItem(body) {
    const servico = api.ADMIN_ADICIONAR_ITEM;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarItens
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarItens() {
    const servico = api.ADMIN_LISTAR_ITENS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarItemPorId
   *
   * @static
   * @param {string} id
   * @memberof Requisicoes
   */
  static async getConsultarItemPorId(id) {
    const servico = api.ADMIN_CONSULTAR_ITEM;
    const url = servico.url(id);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarItem
   *
   * @static
   * @param {object} body
   * @memberof Requisicoes
   */
  static async patchAlterarItem(body) {
    const servico = api.ADMIN_ALTERAR_ITEM;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * deleteExcluirItem
   *
   * @static
   * @param {string} codigo
   * @memberof Requisicoes
   */
  static async deleteExcluirItem(codigo) {
    const servico = api.ADMIN_EXCLUIR_ITEM;
    const url = servico.url(codigo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /** **************************************************************************************************************************
   *
   * ANTIGO
   *
   ************************************************************************************************************************** */

  /**
   * postGerarLog
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postGerarLog(body) {
    const servico = api.LOGS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getIsFunci
   *
   * @static
   * @param {string} email
   * @memberof Requisicoes
   */
  static async getIsFunci(email) {
    const servico = api.IS_FUNCI;
    const url = servico.url(email);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getTurnos
   *
   * @static
   * @memberof Requisicoes
   */
  static async getTurnos() {
    const servico = api.TURNOS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getSocioPorTitulo
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getSocioPorTitulo(titulo) {
    const servico = api.SOCIO_CONSULTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesAvulsosDispPorTitulo
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getConvitesAvulsosDispPorTitulo(titulo) {
    const servico = api.CONVITES_DISP_AVULSO;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesChurrasDispPorTitulo
   *
   * @static
   * @param {string} ano
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getConvitesChurrasDispPorTitulo(ano, titulo) {
    const servico = api.CONVITES_DISP_CHURRAS;
    const url = servico.url(ano, titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesChurrasDispPorReserva
   *
   * @static
   * @param {string} idReserva
   * @param {string} ano
   * @memberof Requisicoes
   */
  static async getConvitesChurrasDispPorReserva(idReserva, ano) {
    const servico = api.CONVITES_DISP_CHURRAS_RESERVA;
    const url = servico.url(idReserva, ano);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postEmitirConvite
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postEmitirConvite(body) {
    const servico = api.CONVITES_EMITIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarConvites
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarConvites(titulo) {
    const servico = api.CONVITES_LISTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarConvite
   *
   * @static
   * @param {string} idConvite
   * @memberof Requisicoes
   */
  static async getConsultarConvite(idConvite) {
    const servico = api.CONVITE;
    const url = servico.url(idConvite);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postConfirmarEntrada
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postConfirmarEntrada(body) {
    const servico = api.CONVITE_ENTRADA;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarConvite
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarConvite(body) {
    const servico = api.CONVITE_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqueiras
   *
   * @static
   * @memberof Requisicoes
   */
  static async getChurrasqueiras() {
    const servico = api.CHURRASQUEIRAS_LISTAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqueiraPorId
   *
   * @static
   * @param {string} idChurrasqueira
   * @memberof Requisicoes
   */
  static async getChurrasqueiraPorId(idChurrasqueira) {
    const servico = api.CHURRASQUEIRAS_CONSULTAR;
    const url = servico.url(idChurrasqueira);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarChurrasqueira
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarChurrasqueira(body) {
    const servico = api.CHURRASQUEIRAS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarReservas
   *
   * @static
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarReservas(dataInicial, dataFinal, titulo) {
    const servico = api.RESERVAS_LISTAR;
    const url = servico.url(dataInicial, dataFinal, titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarReservaPorId
   *
   * @static
   * @param {string} idReserva
   * @memberof Requisicoes
   */
  static async getConsultarReservaPorId(idReserva) {
    const servico = api.RESERVAS_CONSULTAR;
    const url = servico.url(idReserva);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarReserva
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarReserva(body) {
    const servico = api.RESERVAS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getChurrasqDispPorData
   *
   * @static
   * @param {string} data
   * @memberof Requisicoes
   */
  static async getChurrasqDispPorData(data) {
    const servico = api.RESERVAS_DISP_CHURRASQ;
    const url = servico.url(data);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConvitesPorIdReserva
   *
   * @static
   * @param {string} idReserva
   * @memberof Requisicoes
   */
  static async getConvitesPorIdReserva(idReserva) {
    const servico = api.RESERVAS_CONVITES;
    const url = servico.url(idReserva);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postConviteEnviarEmail
   *
   * @static
   * @param {string} convite
   * @memberof Requisicoes
   */
  static async postConviteEnviarEmail(convite) {
    const servico = api.CONVITE_EMAIL;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, convite);
    return this.tratarResposta(response, url);
  }

  /**
   * getReservasDatasDisp
   *
   * @static
   * @memberof Requisicoes
   */
  static async getReservasDatasDisp() {
    const servico = api.RESERVAS_DISP_DATAS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * postEmitirReserva
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postEmitirReserva(body) {
    const servico = api.RESERVAS_EMITIR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarUsuariosSistema
   *
   * @static
   * @param {string} titulo
   * @memberof Requisicoes
   */
  static async getListarUsuariosSistema(titulo) {
    const servico = api.USUARIOS_SIS_LISTAR;
    const url = servico.url(titulo);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getConsultarUsuarioSistema
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getConsultarUsuarioSistema(uid) {
    const servico = api.USUARIOS_SIS_CONSULTAR;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarPerfisUsuario
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getListarPerfisUsuario(uid) {
    const servico = api.USUARIOS_SIS_PERFIS_USUARIO;
    const url = servico.url(uid);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarPerfisSistema
   *
   * @static
   * @memberof Requisicoes
   */
  static async getListarPerfisSistema() {
    const servico = api.USUARIOS_SIS_PERFIS;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /**
   * patchAlterarUsuarioSistema
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async patchAlterarUsuarioSistema(body) {
    const servico = api.USUARIOS_SIS_ALTERAR;
    const url = servico.url();
    const response = await this.requisitar(servico.metodo, url, body);
    return this.tratarResposta(response, url);
  }

  /**
   * getListarAcessosFisicos
   *
   * @static
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string} tipoIdentificador
   * @param {string} identificador
   * @memberof Requisicoes
   */
  static async getListarAcessosFisicos(dataInicial, dataFinal, tipoIdentificador, identificador) {
    const servico = api.ACESSOS_LISTAR;
    const url = servico.url(dataInicial, dataFinal, tipoIdentificador, identificador);
    const response = await this.requisitar(servico.metodo, url);
    return this.tratarResposta(response, url);
  }

  /** ***************************************************************************************** */
  /** ***************************************************************************************** */
  /** ***************************************************************************************** */

  /**
   * getInfo
   *
   * @static
   * @memberof Requisicoes
   */
  static async getInfo() {
    try {
      const servico = api.INFO;
      const response = await this.requisitar(servico.metodo, servico.url());
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }

  /**
   * getTerceiros
   *
   * @static
   * @memberof Requisicoes
   */
  static async getTerceiros() {
    try {
      const servico = api.TERCEIROS;
      const response = await this.requisitar(servico.metodo, servico.url());
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }

  /**
   * getSalt
   *
   * @static
   * @param {string} email
   * @memberof Requisicoes
   */
  static async getSalt(email) {
    try {
      const servico = api.SALT;
      const response = await this.requisitar(servico.metodo, servico.url(email));
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }

  /**
   * postLogin
   *
   * @static
   * @param {objet} body
   * @memberof Requisicoes
   */
  static async postLogin(body) {
    try {
      const servico = api.LOGIN;
      const response = await this.requisitar(servico.metodo, servico.url(), body);
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }

  /**
   * getConvitesDispAvulso
   *
   * @static
   * @param {string} uid
   * @memberof Requisicoes
   */
  static async getConvitesDispAvulso(uid) {
    try {
      const servico = api.CONVITES_DISP_AVULSO;
      const response = await this.requisitar(servico.metodo, servico.url(uid));
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }

  /**
   * getConvitesDispChurrasq
   *
   * @static
   * @param {string} uid
   * @param {string} ano
   * @memberof Requisicoes
   */
  static async getConvitesDispChurrasq(uid, ano) {
    try {
      const servico = api.CONVITES_DISP_CHURRASQ;
      const response = await this.requisitar(servico.metodo, servico.url(uid, ano));
      const { httpStatusCode, data } = response;
      if (httpStatusCode === 200 || httpStatusCode === 412 || httpStatusCode === 428) return data;
      return false;
    } catch (error) {
      return false;
    }
  }
}
