import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Accordion, Button, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AccordionLineToggle, Carregando, Confirmacao } from '../../components';

import { modelUsuario } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default () => {
  const history = useHistory();
  const { uid } = useParams();
  const [carregando, setCarregando] = useState(true);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false, onConfirm: () => {} });
  const [atualizar, setAtualizar] = useState(0);
  const [usuario, setUsuario] = useState({});

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', show: false, onConfirm: () => {} });
  };

  const alterarStatusMatricula = async (idCrianca, status) => {
    setCarregando(true);
    const resp = await Admin.alterarStatusMatricula(idCrianca, status);
    console.log('resp', resp);
    if (resp.erro) {
      alertar('error', resp.erro || 'Erro ao realizar alteração.');
    } else if (resp === true) {
      setAtualizar(atualizar + 1);
    }
    setCarregando(false);
  };

  const excluirCrianca = async idCrianca => {
    fecharConfirmacao();
    setCarregando(true);
    const resp = await Admin.alterarStatusCrianca(uid, idCrianca, 'I');
    console.log('resp', resp);
    if (resp.erro) {
      alertar('error', resp.erro || 'Erro ao realizar alteração.');
    } else if (resp === true) {
      setAtualizar(atualizar + 1);
    }
    setCarregando(false);
  };

  const alterarStatusUsuario = async () => {
    setCarregando(true);
    const { status } = usuario;
    const resp = await Admin.alterarStatusUsuario(uid, status === 'C' ? 'A' : 'C');
    console.log('resp', resp);
    if (resp.erro) {
      alertar('error', resp.erro || 'Erro ao realizar alteração.');
    } else if (resp === true) {
      setAtualizar(atualizar + 1);
    }
    setCarregando(false);
  };

  // Controle de do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      const resp = await Admin.consultarUsuario(uid);
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar o usuário.');
      } else if (resp.uid) {
        setUsuario(modelUsuario(resp));
      }
      setCarregando(false);
    };
    inicializar();
  }, [uid, atualizar]);

  return (
    <>
      <Carregando show={carregando} />
      {confirmacao.show && (
        <Confirmacao mensagem={confirmacao.mensagem} show={confirmacao.show} onConfirm={confirmacao.onConfirm} onCancel={fecharConfirmacao} />
      )}
      <br />
      <Card className="mt-5">
        <Card.Header>
          <Card.Title className="m-0">Cadastro</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-4">{`${usuario.nome || ''}`}</Card.Title>
          <Row>
            <Col>
              <Card.Text>
                Email: <strong>{`${usuario.email || ''}`}</strong>
              </Card.Text>
              <Card.Text>
                Admin: <strong>{`${usuario.admin === true ? 'SIM' : 'NÃO'}`}</strong>
              </Card.Text>
              <Card.Text>
                Data última atividade: <strong>{`${usuario.dtUltimaAtividade || 'Nunca'}`}</strong>
              </Card.Text>
            </Col>
            <Col>
              <Card.Text>
                Telefone: <strong>{`${usuario.telefone || ''}`}</strong>
              </Card.Text>
              <Card.Text>
                Status: <strong>{`${usuario.statusLabel || ''}`}</strong>
              </Card.Text>
            </Col>
          </Row>
          <Accordion defaultActiveKey="0" className="mt-4 mb-2">
            <Card className="border-0">
              <AccordionLineToggle eventKey="0">Crianças</AccordionLineToggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {usuario.criancas &&
                    usuario.criancas.length > 0 &&
                    usuario.criancas.map(c => (
                      <Row className="mb-2" key={`${c.id}`}>
                        <Col>{`${c.nome || ''}`}</Col>
                        <Col>{`${c.idade} anos`}</Col>
                        <Col>{`${c.grauAfinidade || ''}`}</Col>
                        <Col className="d-flex flex-row">
                          Matriculado(a)?
                          <Form.Check
                            checked={c.matriculada}
                            type="checkbox"
                            onChange={() => alterarStatusMatricula(`${c.id}`, !c.matriculada)}
                            className="ml-2"
                          />
                        </Col>
                        <Col>{`${c.statusLabel || ''}`}</Col>
                        <Col className="d-flex flex-row justify-content-end">
                          <Button
                            variant="outline-danger"
                            className="mx-2"
                            size="sm"
                            onClick={() =>
                              setConfirmacao({
                                mensagem: 'Tem certeza que deseja excluir a criança? Esta operação não pode ser desfeita.',
                                show: true,
                                onConfirm: () => excluirCrianca(`${c.id}`),
                                onCancel: fecharConfirmacao,
                              })
                            }
                          >
                            X
                          </Button>
                        </Col>
                      </Row>
                    ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card.Header>Histórico</Card.Header>
          <div className="px-3 py-2" style={{ maxHeight: '25vh', overflow: 'auto' }}>
            {usuario.logs &&
              usuario.logs.length > 0 &&
              usuario.logs.map((a, index) => (
                <Row key={`${index}`}>
                  <Col xs="auto">{`${a.timestamp || ''}`}</Col>
                  <Col>{`${a.acaoLabel || ''}`}</Col>
                </Row>
              ))}
          </div>
          <Row className="mt-4 d-flex justify-content-center">
            <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
              Voltar
            </Button>
            <Button className="mx-2" variant={usuario.status !== 'C' ? 'danger' : 'success'} onClick={() => alterarStatusUsuario()}>
              {usuario.status !== 'C' ? 'Cancelar cadastro' : 'Ativar cadastro'}
            </Button>
            <Button className="mx-2" variant="success" onClick={() => history.push(`/admin/usuarios/nova-crianca/${usuario.uid}`)}>
              Adicionar criança
            </Button>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
