import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { validarEmail } from '../../utils';

export default () => {
  const history = useHistory();
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = values => console.log('values', values);

  return (
    <Card className="mt-4">
      <Card.Header>
        <Card.Title className="m-0">Novo usuário</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <Form.Row className="justify-content-between">
            {/* Nome */}
            <Form.Group as={Col} controlId="nome" className="align-middle mb-0">
              <Form.Label column sm="auto">
                Nome
              </Form.Label>
              <Col xs="8">
                <Form.Control
                  name="nome"
                  type="text"
                  ref={register({
                    required: 'É necessário informar o nome do usuário.',
                    minLength: { value: 5, message: 'O nome deve conter no mínimo 5 caracteres.' },
                    maxLength: { value: 150, message: 'O nome deve conter no máximo 150 caracteres.' },
                  })}
                />
              </Col>
              <Col as={Card.Text} className="text-danger">
                {errors.nome && errors.nome.message}
              </Col>
            </Form.Group>
          </Form.Row>
          <Form.Row className="justify-content-between mt-4">
            {/* Email */}
            <Form.Group as={Col} controlId="email" className="align-middle mb-0">
              <Form.Label column sm="auto">
                E-mail
              </Form.Label>
              <Col xs="8">
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="email@provedor.com"
                  ref={register({
                    required: 'É necessário informar o e-mail do usuário.',
                    validate: value => validarEmail(value) || 'Formato do e-mail inválido.',
                  })}
                />
              </Col>
              <Col as={Card.Text} className="text-danger">
                {errors.email && errors.email.message}
              </Col>
            </Form.Group>
          </Form.Row>
          <Row className="mt-4 d-flex justify-content-center">
            <Button className="mx-2" type="submit">
              Salvar
            </Button>
            <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
              Voltar
            </Button>
            <Button className="mx-2" variant="light" onClick={() => reset()}>
              Limpar
            </Button>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
