import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Requisicoes } from '.';
import { validarTipo } from '../utils';

/**
 *
 *
 * @export
 * @class Auth
 */
export default class Auth {
  static LANGUAGE_CODE = 'pt-br';
  static SESSION_TIME = 900000; // 15 minutos

  /**
   * Cria uma instância singleton da classe Auth.
   * @memberof Auth
   */
  constructor() {
    if (!Auth.instance) {
      this.sessionExpiration = '';
      this.authed = true;
      this.authFunction = () => {};
      Auth.instance = this;
    }
    return Auth.instance;
  }

  static refreshTimer() {
    const auth = new Auth();
    if (auth.timer) {
      const newExpiration = moment().add(Auth.SESSION_TIME, 'seconds').format('HH:mm:ss');
      if (newExpiration === auth.sessionExpiration) return null;
      auth.clearTimer();
      auth.setTimer();
    }
  }

  setAuth(authed) {
    this.authed = authed === true || false;
    this.authFunction(this.authed);
  }

  setAuthFunction(fn = () => {}) {
    this.authFunction = fn;
  }

  setTimer() {
    this.sessionExpiration = moment().add(Auth.SESSION_TIME, 'seconds').format('HH:mm:ss');
    this.timer = setTimeout(() => {
      this.sessionExpiration = '';
      this.clearTimer();
      Auth.sair();
    }, Auth.SESSION_TIME);
  }

  clearTimer() {
    this.sessionExpiration = '';
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  /**
   * setLanguage
   *
   * @static
   * @memberof Auth
   */
  static setLanguage() {
    firebase.auth().languageCode = this.LANGUAGE_CODE;
  }

  /**
   * getUsuario
   *
   * @static
   * @returns {firebase.user}
   * @memberof Auth
   */
  static getUsuario() {
    return firebase.auth().currentUser;
  }

  /**
   * getIdToken
   *
   * @static
   * @returns {firebase.user}
   * @memberof Auth
   */
  static async getIdToken() {
    const usuario = this.getUsuario();
    if (usuario) {
      return await usuario.getIdToken();
    }
    return null;
  }

  /**
   * getApiInfo
   *
   * @static
   * @returns {firebase.apiKey}
   * @memberof Auth
   */
  static getApiInfo() {
    const { options } = firebase.app();
    return options;
  }

  /**
   * onAuth
   *
   * @static
   * @param {function} callback
   * @memberof Auth
   */
  static onAuth(callback) {
    firebase.auth().onAuthStateChanged(user => {
      callback(user);
    });
  }

  /**
   * conectar
   *
   * @static
   * @param {string} email
   * @param {string} senha
   * @returns {boolean}
   * @throws {Object{erro}}
   * @memberof Auth
   */
  static async conectarComEmailESenha(email, senha) {
    const erro = {};

    // As httpOnly cookies are to be used, do not persist any state client side.
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, senha)
        .then(async credential => {
          // Session login endpoint is queried and the session cookie is set.
          // CSRF protection should be taken into account.
          const idToken = await credential.user.getIdToken();
          const { csrfToken } = await Requisicoes.getCsrftoken();
          const session = await Requisicoes.postSessionLogin(idToken, csrfToken);
          console.log('session', session);
          if (session.status === 'success') {
            console.log('chegou1');
            // A page redirect would suffice as the persistence is set to NONE.
            firebase.auth().signOut();
            console.log('chegou2');
            return true;
          }
          // Erros negociais
          if (session.erro && validarTipo(session.erro, 'string')) throw session;
          // Erros da api de autenticacao
          throw new Error(session);
        })
        .catch(error => {
          if (error.erro && validarTipo(error.erro, 'string')) {
            console.log('entrou na validacao');
            erro.msg = `${error.erro}`;
            throw erro;
          }

          erro.code = error.code;
          switch (error.code) {
            case 'auth/invalid-email':
              erro.msg = 'Email/senha inválido(a).';
              break;

            case 'auth/user-disabled':
              erro.msg = 'Esta conta está desabilitada no momento.';
              break;

            case 'auth/user-not-found':
              erro.msg = 'Email/senha inválido(a).';
              break;

            case 'auth/wrong-password':
              erro.msg = 'Email/senha inválido(a).';
              break;

            default:
              erro.msg = 'Houve uma falha na comunicação. Por favor, tente novamente.';
          }
        });

      if (erro.code || erro.msg) throw erro;

      return true;
    } catch (e) {
      firebase.auth().signOut();
      erro.msg = e.msg || e.errorMessage;
      throw erro;
    }
  }

  /**
   * cadastrarComEmail
   *
   * @static
   * @param {object} usuario
   * @returns boolean
   * @memberof Auth
   */
  static async cadastrarComEmail(usuario) {
    const erro = {};
    const { nome, email, senha, senhaConfirmacao, telefone } = usuario;
    try {
      if (senha !== senhaConfirmacao) {
        erro.msg = 'Confirmação de senha não confere.';
        throw erro;
      }

      const { csrfToken } = await Requisicoes.getCsrftoken();

      const cadastro = await Requisicoes.postCadastroUsuario({ ...usuario, 'XSRF-TOKEN': csrfToken });

      if (cadastro.erro) {
        erro.msg = cadastro.erro;
        throw erro;
      }

      if (cadastro === true) return true;
      return false;
    } catch (e) {
      erro.msg = e.msg || e.errorMessage;
      throw erro;
    }
  }

  /**
   * recuperarSenha
   *
   * @static
   * @param {string} email
   * @returns
   * @memberof Auth
   */
  static async recuperarSenha(email) {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * reenviarEmailAtivacao
   *
   * @static
   * @param {string} email
   * @param {string} senha
   * @returns
   * @memberof Auth
   */
  static async reenviarEmailAtivacao(email, senha) {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, senha)
        .then(async credential => {
          // Session login endpoint is queried and the session cookie is set.
          // CSRF protection should be taken into account.
          const user = credential.user;
          const envio = user.sendEmailVerification().catch(() => 'erro');
          if (envio === 'erro') {
            const erro = { erro: 'Erro ao enviar o email.' };
            throw erro;
          }
        });
      return true;
    } catch (error) {
      console.log('error', error);
      return false;
    }
  }

  /**
   * sair
   *
   * @static
   * @memberof Auth
   */
  static async sair() {
    await firebase.auth().signOut();
  }
}
