import React from 'react';
import { AdmDadosProgramaDetalhe, AdmDadosProgramaLista, AdmDadosProgramaNovo } from '.';

export default props => {
  const { query } = props;
  const codigo = query.get('codigo');
  const novoItem = query.get('novoItem');

  if (novoItem) {
    return <AdmDadosProgramaNovo />;
  }

  if (codigo) {
    return <AdmDadosProgramaDetalhe query={query} />;
  }

  return <AdmDadosProgramaLista query={query} />;
};
