import React, { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Carregando, FormItem } from '../../components';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const history = useHistory();
  const [carregando, setCarregando] = useState(false);
  const [resets, setResets] = useState(null);
  const [continuar, setContinuar] = useState(true);
  const [fileAudio, setFileAudio] = useState(null);
  const [fileImagem, setFileImagem] = useState(null);
  const [fileNameAudio, setFileNameAudio] = useState('');
  const [fileNameImagem, setFileNameImagem] = useState('');

  const onSubmit = async values => {
    const { tipo, categoria, texto } = values;

    setCarregando(true);

    try {
      if (!fileAudio || !fileNameAudio) {
        const erro = { msg: 'É necessário enviar um arquivo de áudio.' };
        throw erro;
      }

      const item = new FormData();
      item.append('audio', fileAudio);
      item.append('audioNome', fileNameAudio);
      item.append('imagem', fileImagem);
      item.append('imagemNome', fileNameImagem);
      item.append('idioma', 'pt-br');
      item.append('tipo', tipo);
      item.append('categoria', categoria);
      item.append('texto', texto);

      const resp = await Admin.adicionarItem(item);
      if (resp === true) {
        if (continuar) {
          setResets({ tipo, categoria });
          setCarregando(false);
        } else {
          history.push('/admin/dados?aba=acervo');
        }
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a inclusão do item. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  return (
    <>
      <Carregando show={carregando} />
      <br />
      <Card className="mt-4">
        <Card.Header>
          <Card.Title className="m-0">Novo item</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            Idioma: <strong>Português</strong>
          </Card.Text>
          <FormItem
            novo
            onSubmit={onSubmit}
            setFileAudio={setFileAudio}
            setFileNameAudio={setFileNameAudio}
            setFileImagem={setFileImagem}
            setFileNameImagem={setFileNameImagem}
            resets={resets}
          />
          <Form.Row className="justify-content-center mt-4">
            <Form.Check
              checked={continuar}
              type="checkbox"
              onChange={() => setContinuar(!continuar)}
              className="ml-2"
              label="Continuar incluindo items após salvar"
            />
          </Form.Row>
          <Row>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
