import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import env from '../constants/env';

export default props => {
  const { location } = props;
  const history = useHistory();
  const { pathname = '' } = location;
  const currentRoute = pathname.substr(0, pathname.substr(1, pathname.length).search('/') + 1);

  const admin = true; // TODO: receber info de admin do servidor

  return (
    <Nav
      variant="pills"
      className="navbar navbar-dark border-bottom border-white w-100 m-0 justify-content-between cabecalho"
      defaultActiveKey={currentRoute}
    >
      <Col xs="auto">
        <h3 className="text-white" onClick={() => history.push('/')}>
          Smart Baby Online
        </h3>
      </Col>
      <Col>
        <Row className="justify-content-end">
          <Nav.Item>
            <Nav.Link className="text-white" href="/inicio">
              Início
            </Nav.Link>
          </Nav.Item>
          {env.CADASTRO_CRIANCA_USUARIO && (
            <Nav.Item>
              <Nav.Link className="text-white" href="/adicionar-crianca">
                Adicionar Criança
              </Nav.Link>
            </Nav.Item>
          )}
          {admin && (
            <Nav.Item>
              <Nav.Link className="text-white" href="/admin">
                Administração
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link className="text-white" href="/sair">
              Sair
            </Nav.Link>
          </Nav.Item>
        </Row>
      </Col>
    </Nav>
  );
};
