const firebaseConfig = {
  apiKey: 'AIzaSyBzCBy5n5A4Jljp8kf_z6eOjhZZfPxzEzo',
  authDomain: 'smartbaby-online.firebaseapp.com',
  databaseURL: 'https://smartbaby-online.firebaseio.com',
  projectId: 'smartbaby-online',
  storageBucket: 'smartbaby-online.appspot.com',
  messagingSenderId: '580679106748',
  appId: '1:580679106748:web:e1a8004819d883e95210b9',
  measurementId: 'G-9WTB1DDQWW',
};

export default firebaseConfig;
