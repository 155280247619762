import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Image, Col, Row, Container, Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { Cabecalho, Carregando } from '../../components';
import { Usuario } from '../../services';
import avatares from '../../constants/avatares';
import { alertar } from '../../utils';

const exemplosDoman = require('../../assets/imgs/exemplos-doman.png');

export default () => {
  const location = useLocation();
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [criancas, setCriancas] = useState([]);

  // Controle de do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      const resp = await Usuario.listarCriancas();
      if (resp.erro) {
        console.log('resp.erro', resp.erro);
        console.log('resp', resp);
        if (!`${resp.erro}`.includes('desconhecido')) alertar('error', resp.erro || 'Erro ao consultar as crianças cadastradas.');
      } else if (resp.length > 0) {
        setCriancas(resp);
      }
      setCarregando(false);
    };
    inicializar();
  }, []);

  return (
    <>
      <Cabecalho location={location} />
      <Container fluid className="h-100">
        <Carregando show={carregando} />
        <br />
        <Card className="text-center border-0">
          <Card.Body>
            <Row>
              <Col className="d-flex flex-column justify-content-center align-items-center" style={{ maxWidth: '35vw' }}>
                <Image rounded src={exemplosDoman} style={{ maxWidth: '30vw', aspectRatio: '94.9%' }} />
              </Col>
              <Col className="d-flex flex-column justify-content-center align-items-center">
                <Card.Title as="h1" className="mb-4">
                  SEJA BEM-VINDO!
                </Card.Title>
                {criancas.length > 0 ? (
                  <Row>
                    {criancas.map(crianca => (
                      <Col xs="auto" className="d-flex flex-column" key={crianca.id.toString()}>
                        <a href={`/selecao-programa?idCrianca=${crianca.id || 0}`}>
                          <Image
                            src={avatares.filter(a => a.value === crianca.avatar)[0].image}
                            style={{ borderRadius: '2vw', width: '5vw', aspectRatio: '114,15%' }}
                          />
                          <Card.Text className="my-4 text-secondary">{crianca.nome_reduzido}</Card.Text>
                        </a>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <>
                    <Card.Text className="my-4 text-secondary">Nenhuma criança cadastrada ainda.</Card.Text>
                    <Button variant="primary" onClick={() => history.push('/adicionar-crianca')}>
                      Adicionar Criança
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
