import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Carregando } from '../../components';
import { modelUsuario } from '../../models';

import { Admin } from '../../services';
import { alertar } from '../../utils';

export default () => {
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  // Controle de do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      const resp = await Admin.listarUsuarios();
      console.log('resp', resp);
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar os usuários cadastrados.');
      } else if (resp.length > 0) {
        setUsuarios(resp.map(u => modelUsuario(u)));
      }
      setCarregando(false);
    };
    inicializar();
  }, []);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      <Card className="border-0">
        <Card.Body>
          {/* <Button onClick={() => history.push('/admin/usuarios/novo-usuario')}>Novo usuário</Button> */}
          {/* <br /> */}
          <br />
          <Table striped hover>
            <thead>
              <tr className="bg-dark text-white">
                <th>Usuário</th>
                <th>E-mail</th>
                <th className="text-center">Qtde Crianças</th>
                <th className="text-center">Status</th>
                <th className="text-center">Última Atividade</th>
                <th className="text-center">Crianças Matriculadas</th>
                <th className="text-center">Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map(u => (
                <tr key={`${u.uid}`}>
                  <td>{`${u.nome}`}</td>
                  <td>{`${u.email}`}</td>
                  <td className="text-center">{`${u.qtdeCriancas}`}</td>
                  <td className="text-center">{`${u.statusLabel}`}</td>
                  <td className="text-center">{`${u.dtUltimaAtividade || 'Nunca'}`}</td>
                  <td className="text-center">{`${u.qtdeCriancasMatriculadas}`}</td>
                  <td className="text-center">
                    <a href={`/admin/usuarios/detalhe-usuario/${u.uid}`}>&gt;</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
