import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Carregando, Confirmacao, FormPrograma, ListaGrupos } from '../../components';

import { modelPrograma } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const { query } = props;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false, onConfirm: () => {} });
  const [showLista, setShowLista] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [edicao, setEdicao] = useState(false);
  const [programa, setPrograma] = useState({});
  const labelOrdenacao = { A: 'Ascendente', D: 'Descendente', R: 'Aleatória' };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', show: false, onConfirm: () => {} });
  };

  const cancelarEdicao = () => setEdicao(false);

  const editarPrograma = async values => {
    const { nome, descricao, qtdeMaxSessoes, qtdeRepeticoesSessao, visibilidade, observacao } = values;

    setCarregando(true);

    try {
      const changingPrograma = {
        codigo: programa.codigo,
        nome,
        descricao,
        qtdeMaxSessoes: parseInt(qtdeMaxSessoes, 10),
        qtdeRepeticoesSessao: parseInt(qtdeRepeticoesSessao, 10),
        visibilidade,
        observacao,
      };
      const resp = await Admin.alterarPrograma(changingPrograma);
      if (resp === true) {
        setEdicao(false);
        setAtualizar(atualizar + 1);
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a alteração do programa. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  const excluirPrograma = async () => {
    const { codigo } = programa;

    fecharConfirmacao();
    setCarregando(true);

    try {
      const resp = await Admin.excluirPrograma(codigo);
      if (resp === true) {
        history.push('/admin/dados?aba=programas');
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a exclusão do programa. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  const incluirGrupo = async (codigoGrupo, dia, ordemApresentacao) => {
    try {
      fecharConfirmacao();
      setShowLista(false);
      setCarregando(true);
      if (!dia > 0 && !dia <= 9999) return alertar('warning', 'Dia deve ser entre 1 e 9999');
      const vinculacao = await Admin.vincularGrupoPrograma({
        tipoVinculo: 'I',
        codigoPrograma: programa.codigo,
        dia: parseInt(dia, 10),
        ordemApresentacao,
        codigoGrupo,
      });
      if (vinculacao.erro) {
        alertar('error', vinculacao.erro);
      }
      if (vinculacao === true) {
        setAtualizar(atualizar + 1);
        alertar('success', 'Grupo vinculado com sucesso.');
      }
    } catch (error) {
      alertar('error', 'Erro ao tentar vinvular o grupo ao programa.');
    } finally {
      setCarregando(false);
    }
  };

  const excluirGrupo = async (codigoGrupo, dia) => {
    try {
      fecharConfirmacao();
      setCarregando(true);
      const vinculacao = await Admin.vincularGrupoPrograma({
        tipoVinculo: 'E',
        codigoPrograma: programa.codigo,
        dia: parseInt(dia, 10),
        codigoGrupo,
      });
      if (vinculacao.erro) {
        alertar('error', vinculacao.erro);
      }
      if (vinculacao === true) {
        setAtualizar(atualizar + 1);
        alertar('success', 'Grupo desvinculado com sucesso.');
      }
    } catch (error) {
      alertar('error', 'Erro ao tentar desvinvular o grupo do programa.');
    } finally {
      setCarregando(false);
    }
  };

  // Controle do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      const resp = await Admin.consultarProgramaPorCodigo(query.get('codigo'));
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar o programa.');
      } else if (resp.codigo) {
        setPrograma(modelPrograma(resp));
      }
      setCarregando(false);
    };
    inicializar();
  }, [query, atualizar]);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      {confirmacao.show ? (
        <Confirmacao mensagem={confirmacao.mensagem} show={confirmacao.show} onConfirm={confirmacao.onConfirm} onCancel={fecharConfirmacao} />
      ) : null}
      {showLista && <ListaGrupos show={showLista} onAdd={incluirGrupo} onCancel={() => setShowLista(false)} />}
      <Card className="mt-2">
        <Card.Header>
          <Card.Title className="m-0">Programa</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-4">{`${programa.nome || ''}`}</Card.Title>
          {edicao === true ? (
            <FormPrograma novo={false} onSubmit={editarPrograma} onCancel={cancelarEdicao} defaults={programa} />
          ) : (
            <>
              <Row>
                <Col>
                  <Card.Text>
                    Código: <strong>{`${programa.codigo || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Nome: <strong>{`${programa.nome || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Qtde. Máx de Sessões: <strong>{`${programa.qtdeMaxSessoes || 0}`}</strong>
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    Visibilidade: <strong>{`${programa.visibilidadeLabel || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Descrição: <strong>{`${programa.descricao || ''}`}</strong>
                  </Card.Text>
                  <Card.Text>
                    Qtde. de Repetições por Sessão: <strong>{`${programa.qtdeRepeticoesSessao || 0}`}</strong>
                  </Card.Text>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="auto">
                  <Card.Text>Observações:</Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    <strong>{`${programa.observaco || ''}`}</strong>
                  </Card.Text>
                </Col>
              </Row>

              <Row className="mt-4 d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=programas')}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="info" onClick={() => setEdicao(true)}>
                  Editar
                </Button>
                <Button
                  className="mx-2"
                  variant="danger"
                  onClick={() =>
                    setConfirmacao({
                      mensagem: 'Tem certeza que deseja excluir o programa? Esta operação não pode ser desfeita.',
                      show: true,
                      onConfirm: excluirPrograma,
                    })
                  }
                >
                  Excluir
                </Button>
                <Button className="mx-2" variant="success" onClick={() => setShowLista(true)}>
                  Adicionar Grupo
                </Button>
              </Row>
              {programa.grupos && (
                <>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <Card.Title className="mb-4">Grupos vinculados</Card.Title>
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th className="text-center">Código</th>
                            <th className="text-center">Nome</th>
                            <th className="text-center">Nível</th>
                            <th className="text-center">Dia</th>
                            <th className="text-center">Ordenação</th>
                            <th className="text-center"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {programa.grupos.map(g => (
                            <tr key={`${g.codigo}`}>
                              <td className="text-center align-middle">{g.codigoGrupo}</td>
                              <td className="text-center align-middle">{g.nome}</td>
                              <td className="text-center align-middle">{g.nivel}</td>
                              <td className="text-center align-middle">{g.dia}</td>
                              <td className="text-center align-middle">{labelOrdenacao[g.ordemApresentacao] || 'Não definida'}</td>
                              <td className="text-center align-middle">
                                <Button
                                  variant="success"
                                  onClick={() =>
                                    setConfirmacao({
                                      mensagem: 'Tem certeza que deseja desvincular o grupo do programa?',
                                      show: true,
                                      onConfirm: () => excluirGrupo(g.codigoGrupo, g.dia),
                                    })
                                  }
                                >
                                  Excluir
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
