import moment from 'moment';

/**
 * @method modelLog
 * @description Modelo padrão de objeto da classe
 * @param {string} realizador - Id do usuário
 * @param {string} tipoRealizador - Tipo de realizador [U Usuario; C Crianca]
 * @param {string} acao - Ação do log
 * @param {string} acaoLabel - Ação do log
 * @param {string} origem - Origem da chamada
 * @param {string} descricao - Descrição
 * @param {boolean} in_historico - Indicador de item de histórico
 * @param {timestamp} timestamp - momento do registro
 * @returns {object} Objeto formatado
 */
export default ({
  realizador,
  tipo_realizador = '',
  acao = '',
  acao_label = '',
  descricao = '',
  origem = '',
  in_historico = false,
  timestamp = null,
}) => {
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);

  return {
    realizador,
    tipoRealizador: tipo_realizador,
    acao,
    acaoLabel: acao_label,
    descricao,
    origem,
    inHistorico: in_historico,
    timestamp: getDataHora(timestamp),
  };
};
