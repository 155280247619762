import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AdminPlayer, Carregando, Imagem } from '../../components';
import { modelItem } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const { query } = props;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const { handleSubmit, register, errors } = useForm();
  const [itens, setItens] = useState([]);

  // Itens de query
  const qTipo = query.get('qTipo') || '';
  const qCategoria = query.get('qCategoria') || '';
  const qTexto = query.get('qTexto') || '';

  const onSubmit = async values => {
    const { tipo, categoria, texto } = values;

    setCarregando(true);

    try {
      let url = '/admin/dados?aba=acervo';

      if (tipo) url = `${url}&qTipo=${tipo}`;
      if (categoria) url = `${url}&qCategoria=${categoria}`;
      if (texto) url = `${url}&qTexto=${texto}`;

      if (tipo !== qTipo || categoria !== qCategoria || texto !== qTexto) {
        console.log('url', url);
        history.push(url);
      } else {
        setCarregando(false);
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);

      const resp = await Admin.listarItens();
      console.log('resp', resp);
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar os itens cadastrados.');
      } else if (resp.length > 0) {
        const filtro = resp.filter(g => {
          if (qTipo || qCategoria || qTexto) {
            let selected = true;
            if (qTipo) selected = selected && `${g.tipo}`.toLowerCase() === `${qTipo}`.toLowerCase();
            if (qCategoria) selected = selected && `${g.categoria}`.toLowerCase() === `${qCategoria}`.toLowerCase();
            if (qTexto) selected = selected && `${g.texto}`.toLowerCase() === `${qTexto}`.toLowerCase();
            return selected;
          }
          return true;
        });
        setItens(filtro.map(i => modelItem(i)));
      }
      setCarregando(false);
    };
    inicializar();
  }, [qTipo, qCategoria, qTexto]);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      <Card className="border-0">
        <Card.Body>
          <Row>
            <Col className="justify-content-between">
              <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
                <Form.Row className="justify-content-between">
                  {/* tipo */}
                  <Form.Group as={Col} controlId="tipo" className="align-middle mb-0">
                    <Form.Control name="tipo" type="text" placeholder="Tipo" defaultValue={qTipo || ''} ref={register({})} />
                    <Col as={Card.Text} className="text-danger">
                      {errors.tipo && errors.tipo.message}
                    </Col>
                  </Form.Group>
                  {/* categoria */}
                  <Form.Group as={Col} controlId="categoria" className="align-middle mb-0">
                    <Form.Control name="categoria" type="text" placeholder="Categoria" defaultValue={qCategoria || ''} ref={register({})} />
                    <Col as={Card.Text} className="text-danger">
                      {errors.categoria && errors.categoria.message}
                    </Col>
                  </Form.Group>
                  {/* texto */}
                  <Form.Group as={Col} controlId="texto" className="align-middle mb-0">
                    <Form.Control name="texto" type="text" placeholder="Texto" defaultValue={qTexto || ''} ref={register({})} />
                    <Col as={Card.Text} className="text-danger">
                      {errors.texto && errors.texto.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto" className="d-flex">
                    <Button className="mx-2" type="submit">
                      Buscar
                    </Button>
                    <Button className="ml-2" variant="outline-dark" type="reset">
                      Limpar
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="d-flex flex-column align-items-end">
              <Button variant="success" onClick={() => history.push('/admin/dados?aba=acervo&novoItem=1')}>
                Novo Item
              </Button>
            </Col>
          </Row>
          <Table striped hover>
            <thead>
              <tr className="bg-dark text-white">
                <th className="text-center">Tipo</th>
                <th className="text-center">Categoria</th>
                <th className="text-center">Texto</th>
                <th className="text-center">Imagem</th>
                <th className="text-center">Áudio</th>
                <th className="text-center">Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {itens.length > 0 ? (
                itens.map(i => (
                  <tr key={`${i.id}`}>
                    <td className="text-center">{i.tipo || ''}</td>
                    <td className="text-center">{i.categoria || ''}</td>
                    <td className="text-center">{i.texto || ''}</td>
                    <td className="text-center">
                      <Imagem idItem={`${i.id}`} maxHeight={40} />
                    </td>
                    <td className="text-center">
                      <AdminPlayer idItem={`${i.id}`} />
                    </td>
                    <td className="text-center">
                      <a href={`/admin/dados?aba=acervo&idItem=${i.id}`}>&gt;</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="6">
                    Nenhum item encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
