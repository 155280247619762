import React from 'react';
import { api } from '../constants';

/**
 * Imagem
 *
 * @param {number} idItem
 * @param {number} maxHeight
 * @returns
 */
const Imagem = props => {
  const { idItem, maxHeight = 40, onLoad = () => {} } = props;
  const loading = require('../assets/imgs/loading.png');
  const servico = api.IMAGEM;
  const url = servico.url(idItem);
  return (
    <picture>
      <img src={url} alt={`Imagem do item número ${idItem}.`} style={{ maxHeight }} onLoad={() => onLoad()} />
      <img data={loading} type="image/png" style={{ maxHeight }} />
    </picture>
  );
};
export default Imagem;
