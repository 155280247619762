import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { modelItem } from '../models';
import { Admin } from '../services';
import { alertar } from '../utils';
import Carregando from './Carregando';

const ListaItens = props => {
  const { show = false, onAdd = () => {}, onCancel = () => {} } = props;
  const [carregando, setCarregando] = useState(true);
  const [ordem, setOrdem] = useState({}); // { 01P001: 1, 01P002: '', 01P003: 20 } O código é uma propriedade com seu valor a ordem escolhida
  const [itens, setItens] = useState([]);

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);

      const resp = await Admin.listarItens();
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar os itens cadastrados.');
      } else if (resp.length > 0) {
        resp.map(i => setOrdem({ ...ordem, [i.id]: '' }));
        setItens(resp.map(i => modelItem(i)));
      }
      setCarregando(false);
    };
    inicializar();
  }, []);

  if (carregando) return <Carregando show={carregando} />;

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} onHide={onCancel} className="bg-invisible" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Selecione um item para adicionar:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="text-center">Idioma</th>
                <th className="text-center">Tipo</th>
                <th className="text-center">Categoria</th>
                <th className="text-center">Texto</th>
                <th className="text-center">Status</th>
                <th className="text-center">
                  Ordem <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                </th>
                <th className="text-center"> </th>
              </tr>
            </thead>
            <tbody>
              {itens.length > 0 &&
                itens.map(i => (
                  <tr key={`${i.id}`}>
                    <td className="text-center align-middle">{i.idioma}</td>
                    <td className="text-center align-middle">{i.tipo}</td>
                    <td className="text-center align-middle">{i.categoria}</td>
                    <td className="text-center align-middle">{i.texto}</td>
                    <td className="text-center align-middle">{i.statusLabel}</td>
                    <td className="text-center align-middle">
                      <Form.Control
                        type="text"
                        name="dia"
                        maxLength={4}
                        style={{ width: 60 }}
                        value={ordem[i.id]}
                        onChange={event => setOrdem({ ...ordem, [i.id]: `${event.target.value}`.replace(/\D/g, '') })}
                      />
                    </td>
                    <td className="text-center align-middle">
                      <Button variant="success" onClick={() => onAdd(i.id, ordem[i.id], 'I')}>
                        Adicionar
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onCancel}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default ListaItens;
