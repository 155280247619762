import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Carregando } from '../../components';
import { modelPrograma } from '../../models';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default props => {
  const { query } = props;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const { handleSubmit, register, errors } = useForm();
  const [programas, setProgramas] = useState([]);

  // Itens de query
  const qCodigo = query.get('qCodigo') || '';
  const qNome = query.get('qNome') || '';

  const onSubmit = async values => {
    const { codigo, nome } = values;

    setCarregando(true);

    try {
      let url = '/admin/dados?aba=programas';

      if (codigo) url = `${url}&qCodigo=${codigo}`;
      if (nome) url = `${url}&qNome=${nome}`;

      if (codigo !== qCodigo || nome !== qNome) {
        console.log('url', url);
        history.push(url);
      } else {
        setCarregando(false);
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);

      const resp = await Admin.listarProgramas();
      if (resp.erro) {
        alertar('error', resp.erro || 'Erro ao consultar os programas cadastrados.');
      } else if (resp.length > 0) {
        const filtro = resp.filter(g => {
          if (qCodigo || qNome) {
            let selected = true;
            if (qCodigo) selected = selected && `${g.codigo}`.toLowerCase() === `${qCodigo}`.toLowerCase();
            if (qNome) selected = selected && `${g.nome}`.toLowerCase() === `${qNome}`.toLowerCase();
            return selected;
          }
          return true;
        });
        setProgramas(filtro.map(g => modelPrograma(g)));
      }
      setCarregando(false);
    };
    inicializar();
  }, [qCodigo, qNome]);

  return (
    <>
      <Carregando show={carregando} />
      <br />
      <Card className="border-0">
        <Card.Body>
          <Row>
            <Col className="justify-content-between">
              <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
                <Form.Row className="justify-content-between">
                  {/* codigo */}
                  <Form.Group as={Col} controlId="codigo" className="align-middle mb-0">
                    <Form.Control name="codigo" type="text" placeholder="Código" defaultValue={qCodigo || ''} ref={register({})} />
                    <Col as={Card.Text} className="text-danger">
                      {errors.codigo && errors.codigo.message}
                    </Col>
                  </Form.Group>
                  {/* nome */}
                  <Form.Group as={Col} controlId="nome" className="align-middle mb-0">
                    <Form.Control name="nome" type="text" placeholder="Nome" defaultValue={qNome || ''} ref={register({})} />
                    <Col as={Card.Text} className="text-danger">
                      {errors.nome && errors.nome.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto" className="d-flex">
                    <Button className="mx-2" type="submit">
                      Buscar
                    </Button>
                    <Button className="ml-2" variant="outline-dark" type="reset">
                      Limpar
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
          <Row className="my-4">
            <Col className="d-flex flex-column align-items-end">
              <Button variant="success" onClick={() => history.push('/admin/dados?aba=programas&novoItem=1')}>
                Novo Programa
              </Button>
            </Col>
          </Row>
          <Table striped hover>
            <thead>
              <tr className="bg-dark text-white">
                <th>Código</th>
                <th className="text-center">Nome</th>
                <th className="text-center">Qtde Max Sessões</th>
                <th className="text-center">Qtde Repetições/Sessão</th>
                {/* <th className="text-center">Qtde Grupos</th> */}
                <th className="text-center">Visibilidade</th>
                <th className="text-center">Status</th>
                <th className="text-center">Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {programas.length > 0 ? (
                programas.map(p => (
                  <tr key={`${p.codigo}`}>
                    <td>{p.codigo || ''}</td>
                    <td className="text-center">{p.nome || ''}</td>
                    <td className="text-center">{p.qtdeMaxSessoes || ''}</td>
                    <td className="text-center">{p.qtdeRepeticoesSessao || ''}</td>
                    {/* <td className="text-center">{p.grupos.length || '0'}</td> -- Campo não existe vindo da API */}
                    <td className="text-center">{p.visibilidadeLabel || ''}</td>
                    <td className="text-center">{p.statusLabel || ''}</td>
                    <td className="text-center">
                      <a href={`/admin/dados?aba=programas&codigo=${p.codigo}`}>&gt;</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan="8">
                    Nenhum programa encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
