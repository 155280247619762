import moment from 'moment';
import modelGrupo from './modelGrupo';

/**
 * @method modelPrograma
 * @description Modelo padrão de objeto da classe
 * @param {string} codigo - código do programa
 * @param {string} nome - nome
 * @param {string} descricao - descrição
 * @param {number} qtdeMaxSessoes - qtdeMaxSessoes
 * @param {number} qtdeRepeticoesSessao - qtdeRepeticoesSessao
 * @param {string} visibilidade - visibilidade
 * @param {string} visibilidadeLabel - visibilidadeLabel
 * @param {string} observacao - observacao
 * @param {string} status - status
 * @param {string} statusLAbel - status formatado
 * @param {array} dias - lista dos grupos vinculados a cada dia do programa
 * @param {timestamp} timestamp - momento do registro
 * @returns {object} Objeto formatado
 */
export default ({
  codigo,
  nome = '',
  descricao = '',
  qtde_max_sessoes = 0,
  qtde_repeticoes_sessao = 0,
  qtdeMaxSessoes = 0,
  qtdeRepeticoesSessao = 0,
  visibilidade = '',
  observacao = '',
  status = '',
  dias = [],
  grupos = [],
  timestamp = null,
}) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'I') return 'INATIVO';
    if (status === 'S') return 'SUSPENSO';
    if (status === 'C') return 'CANCELADO';
    return 'INVÁLIDO';
  };
  const getVisibilidadeLabel = () => {
    if (visibilidade === 'A') return 'ADMIN';
    if (visibilidade === 'P') return 'PÚBLICO';
    if (visibilidade === 'E') return 'EM EDIÇÃO';
    return 'Não definido';
  };
  const getDataHora = data => (data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : null);

  return {
    codigo,
    nome,
    descricao,
    qtdeMaxSessoes: qtde_max_sessoes || qtdeMaxSessoes,
    qtdeRepeticoesSessao: qtde_repeticoes_sessao || qtdeRepeticoesSessao,
    visibilidade,
    visibilidadeLabel: getVisibilidadeLabel(),
    observacao,
    status,
    statusLabel: getStatusLabel(),
    dias,
    grupos: grupos,
    timestamp: getDataHora(timestamp),
  };
};
