import React from 'react';
import AdmDadosAcervoDetalhe from './AdmDadosAcervoDetalhe';
import AdmDadosAcervoNovo from './AdmDadosAcervoNovo';
import AdmDadosAcervoLista from './AdmDadosAcervoLista';

export default props => {
  const { query } = props;
  const idItem = query.get('idItem');
  const novoItem = query.get('novoItem');

  if (novoItem) {
    return <AdmDadosAcervoNovo />;
  }

  if (idItem) {
    return <AdmDadosAcervoDetalhe query={query} />;
  }

  return <AdmDadosAcervoLista query={query} />;
};
