import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Container, Card, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import { Cabecalho, Carregando, Voltar } from '../../components';
import { Usuario } from '../../services';
import { alertar, getQuery } from '../../utils';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const query = getQuery(location);
  const idCrianca = query.get('idCrianca');
  const codigoPrograma = query.get('codigoPrograma');
  const [isAdmin, setIsAdmin] = useState(true);
  const [carregando, setCarregando] = useState(true);
  const [conteudo, setConteudo] = useState({});
  const cookieOpcoes = {
    audio: 'C',
    imagem: 'C',
    ...JSON.parse(Cookies.get(`opcoes.${idCrianca}.${codigoPrograma}`) || '{ "audio": "C", "imagem": "C" }'),
  };
  const [opcoes, setOpcoes] = useState(cookieOpcoes);

  useEffect(() => {
    const inicializar = async () => {
      const consulta = await Usuario.consultarConteudo(idCrianca, codigoPrograma);
      console.log('consulta', consulta);
      if (consulta.erro) {
        alertar('error', consulta.erro || 'Erro ao consultar conteúdo.');
      } else if (consulta.codigoPrograma) {
        setConteudo(consulta);
      }
      setCarregando(false);
    };
    inicializar();
  }, [idCrianca, codigoPrograma]);

  const salvar = () => {
    Cookies.set(`opcoes.${idCrianca}.${codigoPrograma}`, JSON.stringify(opcoes), { expires: 365 });
    history.push(`/opcoes-apresentacao?idCrianca=${idCrianca}&codigoPrograma=${codigoPrograma}`);
  };

  return (
    <>
      <Cabecalho location={location} />
      <Container fluid className="h-100">
        <Carregando show={carregando} />
        <br />
        <Voltar to={`/opcoes-apresentacao?idCrianca=${idCrianca}&codigoPrograma=${codigoPrograma}`} />
        {codigoPrograma && (
          <Card>
            <Card.Header>
              <Row className="mx-1 d-flex justify-content-between">
                <Card.Title>{conteudo.nomeCrianca || 'Erro ao obter nome da criança'}</Card.Title>
                <Card.Title>Programa {conteudo.nomePrograma || 'Nome não encontrado'}</Card.Title>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mx-1">
                <Card.Title className="mr-2">Informações sobre o programa:</Card.Title>
                <Card.Text>{conteudo.descricao || 'Descrição não encontrada.'}</Card.Text>
              </Row>
              <br />
              <Row className="mx-1">
                <Card.Title className="mr-2">Opções de Apresentacao:</Card.Title>
              </Row>
              <Row className="mx-1">
                <input
                  checked={opcoes.audio === 'C'}
                  className="mb-2 mr-2"
                  type="radio"
                  id="com-audio"
                  name="audio"
                  value="C"
                  onChange={event => setOpcoes({ ...opcoes, audio: event.target.value })}
                />
                <label className="mr-4" htmlFor="com-audio">
                  Com áudio
                </label>
                <input
                  checked={opcoes.audio === 'S'}
                  className="mb-2 mr-2"
                  type="radio"
                  id="sem-audio"
                  name="audio"
                  value="S"
                  onChange={event => setOpcoes({ ...opcoes, audio: event.target.value })}
                />
                <label htmlFor="sem-audio">Sem áudio</label>
              </Row>
              <Row className="mx-1">
                <input
                  checked={opcoes.imagem === 'C'}
                  className="mb-2 mr-2"
                  type="radio"
                  id="com-imagem"
                  name="imagem"
                  value="C"
                  onChange={event => setOpcoes({ ...opcoes, imagem: event.target.value })}
                />
                <label className="mr-4" htmlFor="com-imagem">
                  Com imagem
                </label>
                <input
                  checked={opcoes.imagem === 'S'}
                  className="mb-2 mr-2"
                  type="radio"
                  id="sem-imagem"
                  name="imagem"
                  value="S"
                  onChange={event => setOpcoes({ ...opcoes, imagem: event.target.value })}
                />
                <label htmlFor="sem-imagem">Sem imagem</label>
              </Row>
              <hr />
              <Row className="mx-1 d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Cancelar
                </Button>
                <Button variant="success" onClick={() => salvar()}>
                  Salvar
                </Button>
              </Row>
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};
