const ambiente = process.env.REACT_APP_ENV === 'development' ? 'development' : 'production';

const env = {
  SECRET: 'm1n4sS3cr3t',
  SERVIDOR: ambiente,
  CADASTRO_CRIANCA_USUARIO: true,
  DESENV_PAGINA_INICIO: '/',
  // URL_API: ambiente === 'development' ? 'https://smartbabyonline.com.br:3000' : 'https://smartbabyonline.com.br:3000',
  URL_API: ambiente === 'development' ? 'https://3zsmartapps.dev.br:2999' : 'https://3zsmartapps.dev.br:2999',
  // URL_API: ambiente === 'development' ? 'http://localhost:2999' : 'https://3zsmartapps.dev.br:3000',
};

export default env;
