import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Carregando, FormGrupo } from '../../components';
import { Admin } from '../../services';
import { alertar } from '../../utils';

export default () => {
  const history = useHistory();
  const [carregando, setCarregando] = useState(false);

  const onSubmit = async values => {
    const { codigo, nome, nivel } = values;

    setCarregando(true);

    try {
      const grupo = { codigo: `${codigo}`.toUpperCase(), nome, nivel };

      const resp = await Admin.adicionarGrupo(grupo);
      if (resp === true) {
        history.push(`/admin/dados?aba=grupos&codigo=${codigo}`);
      } else {
        const erro = { msg: resp.erro || 'Erro ao realizar a inclusão do grupo. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  return (
    <>
      <Carregando show={carregando} />
      <br />
      <Card className="mt-4">
        <Card.Header>
          <Card.Title className="m-0">Novo grupo</Card.Title>
        </Card.Header>
        <Card.Body>
          <FormGrupo novo onSubmit={onSubmit} />
          <Row>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
