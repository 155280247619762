import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Cabecalho, MenuAdmin } from '../../components';
import NoMatch from '../../components/NoMatch';
import AdmDados from './AdmDados';
import AdmUsuarios from './AdmUsuarios';
import AdmUsuarioDetalhe from './AdmUsuarioDetalhe';
import AdmUsuarioNovo from './AdmUsuarioNovo';
import AdmDashboards from './AdmDashboards';
import { AdmUsuarioNovaCrianca } from '.';

export default () => {
  const location = useLocation();
  const admin = true; // TODO: receber info de admin do servidor

  if (!admin) {
    return (
      <Container fluid className="h-100">
        <br />
        <Card className="text-center border-0">
          <Card.Body>
            <Card.Title className="text-danger">Área restrita</Card.Title>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <>
      <Cabecalho location={location} />
      <Row className="w-100 flex-grow-1">
        <MenuAdmin location={location} />
        <Col as="div" className="rolagem">
          <Container fluid className="h-100">
            <Switch>
              {/* ************************************************************************************* */}
              {/* Gestão de Usuarios */}
              {/* ************************************************************************************* */}
              <Route exact path="/admin" component={AdmUsuarios} />
              <Route exact path="/admin/usuarios" component={AdmUsuarios} />
              <Route exact path="/admin/usuarios/novo-usuario" component={AdmUsuarioNovo} />
              <Route exact path="/admin/usuarios/nova-crianca/:uid" component={AdmUsuarioNovaCrianca} />
              <Route exact path="/admin/usuarios/detalhe-usuario/:uid" component={AdmUsuarioDetalhe} />

              {/* ************************************************************************************* */}
              {/* Banco de dados */}
              {/* ************************************************************************************* */}
              <Route path="/admin/dados" component={AdmDados} />
              <Route path="/admin/dados/:aba" component={AdmDados} />

              {/* ************************************************************************************* */}
              {/* Dashboard */}
              {/* ************************************************************************************* */}
              <Route path="/admin/dashboards" component={AdmDashboards} />

              {/* ************************************************************************************* */}
              {/* NoMatch */}
              {/* ************************************************************************************* */}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </Col>
      </Row>
    </>
  );
};
