import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Carregando, Imagem, UserPlayer } from '../../components';
import { alertar } from '../../utils';

export default () => {
  const location = useLocation();
  const { conteudo = {}, opcoes: opcoesExibicao, idCrianca, codigoPrograma } = location.state;
  const history = useHistory();
  const [carregando, setCarregando] = useState(true);
  const [itens, setItens] = useState([]);
  const [itensAudio, setItensAudio] = useState([]);
  const [item, setItem] = useState({});
  const [opcoes, setOpcoes] = useState({});
  const [ready, setReady] = useState({});
  const [startavel, setStartavel] = useState(false);
  const [textoBase, setTextoBase] = useState('Prepare-se');
  const multiplicadorTimer = 1;
  const ajusteReguaMeio = 120;
  const ajusteReguaFinal = 250;

  const proximoItem = (idItem, faseAtual) => {
    if (idItem === 'Inicio') {
      if (textoBase === 'Prepare-se') setTextoBase('3');
      if (textoBase === '3') setTextoBase('2');
      if (textoBase === '2') setTextoBase('1');
      if (textoBase === '1') setTextoBase('Começando...');
      if (textoBase === 'Começando...') {
        setItem({ ...itens[0], fase: 'texto' });
      }
    } else if (idItem === 'Fim') {
      history.push(`/opcoes-apresentacao?idCrianca=${idCrianca}&codigoPrograma=${codigoPrograma}`);
    } else {
      if (faseAtual === 'texto' && opcoesExibicao.imagem === 'C') {
        // TODO: Registrar início da atividade
        setItem({ ...item, fase: 'imagem' });
      } else {
        const filtro = itens.filter(i => i.idItem !== idItem);
        setItens(filtro);
        if (filtro.length > 0) {
          setItem({ ...filtro[0], fase: 'texto' });
        } else {
          // TODO: Registrar fim da atividade
          setItem({ idItem: 'Fim', fase: 'texto' });
        }
      }
    }
  };

  const onLoad = (midia, idItem) => {
    setReady({ ...ready, [idItem]: { ...ready[idItem], [midia]: true } });
    if (
      ready[idItem] &&
      (ready[idItem].imagem === true || midia === 'imagem' || item.fase === 'texto' || midia === 'texto') &&
      (ready[idItem].audio === true || midia === 'audio') &&
      idItem === item.idItem
    ) {
      const audioController = document.getElementById(`audio-id${item.idItem}`);
      if (audioController) audioController.play();
      setStartavel(true);
    }
  };

  useEffect(() => {
    if (item.idItem && item.fase === 'texto' && startavel) onLoad('texto', item.idItem);
  }, [item, startavel]);

  useEffect(() => {
    const inicializar = async () => {
      const { atividades = {} } = conteudo;
      const { itensDiaAtual = [] } = atividades;

      if (!opcoesExibicao.audio || !opcoesExibicao.imagem) {
        alertar('error', 'Erro ao carregar opções do conteúdo.');
        history.goBack();
      }
      if (!Array.isArray(itensDiaAtual) || itensDiaAtual.length <= 0) {
        alertar('error', 'Erro ao carregar itens do conteúdo.');
        history.goBack();
      }
      const readyIniState = {};
      itensDiaAtual.forEach(i => (readyIniState[i.idItem] = { audio: false, imagem: false }));
      setReady(readyIniState);
      setItens(itensDiaAtual);
      setItensAudio(itensDiaAtual);
      setOpcoes(opcoesExibicao);
      setItem({ idItem: 'Inicio', fase: 'texto' });

      setCarregando(false);
    };
    inicializar();
  }, [location.state, history, conteudo, opcoesExibicao]);

  if (carregando) return <Carregando show={carregando} />;

  if (item.idItem === 'Inicio') {
    setTimeout(() => proximoItem('Inicio', 'texto'), textoBase === 'Prepare-se' ? 3000 * multiplicadorTimer : 1000 * multiplicadorTimer);
    return (
      <>
        <Container fluid className="h-100 m-0 border-0 text-center d-flex justify-content-center align-items-center">
          <Card className="m-0 border-0 text-center d-flex justify-content-center align-items-center">
            <div
              className="text-adapt text-danger"
              style={{
                fontSize: `calc(16px + (250 - 16 + (${ajusteReguaMeio} / (20 - ${ajusteReguaMeio}) * ${ajusteReguaFinal} + ${ajusteReguaFinal})) * (100vmax - 200px) / (1200 - 200))`,
              }}
            >
              {textoBase}
            </div>
          </Card>
        </Container>
      </>
    );
  }

  if (item.idItem === 'Fim') {
    setTimeout(() => proximoItem('Fim', 'texto'), 3000 * multiplicadorTimer);
    const textoBase = 'Parabéns!';
    return (
      <>
        <Container fluid className="h-100 m-0 border-0 text-center d-flex justify-content-center align-items-center">
          <Card className="m-0 border-0 text-center d-flex justify-content-center align-items-center">
            <div
              className="text-adapt text-danger"
              style={{
                fontSize: `calc(16px + (250 - 16 + (${ajusteReguaMeio} / (${textoBase} - ${ajusteReguaMeio}) * ${ajusteReguaFinal} + ${ajusteReguaFinal})) * (100vmax - 200px) / (1200 - 200))`,
              }}
            >
              {textoBase}
            </div>
          </Card>
        </Container>
      </>
    );
  }

  if (item.idItem) {
    return (
      <>
        <Container fluid className="h-100 m-0 border-0 text-center d-flex justify-content-center align-items-center">
          <Card className="m-0 border-0 text-center d-flex justify-content-center align-items-center">
            <div
              className="text-adapt text-danger"
              style={{
                fontSize: `calc(16px + (250 - 16 + (${ajusteReguaMeio} / (${
                  item.texto.length || 0
                } - ${ajusteReguaMeio}) * ${ajusteReguaFinal} + ${ajusteReguaFinal})) * (100vmax - 200px) / (1200 - 200))`,
              }}
            >
              {item.fase === 'texto' && item.texto}
              {item.fase === 'imagem' && <Imagem idItem={item.idItem} maxHeight={window.innerHeight} onLoad={() => onLoad('imagem', item.idItem)} />}
            </div>
          </Card>
          {/* TODO: Revisar a repetição do audio para as imagens */}
          {itensAudio.map(i => (
            <UserPlayer
              key={`${i.idItem}`}
              idItem={i.idItem}
              audio={opcoes.audio === 'C'}
              onEnd={() => proximoItem(i.idItem, item.fase)}
              onLoad={() => onLoad('audio', i.idItem)}
            />
          ))}
        </Container>
      </>
    );
  }

  return <Container fluid className="h-100 m-0 border-0 text-center d-flex justify-content-center align-items-center" />;
};
