import React from 'react';
import { Modal, Spinner, Row } from 'react-bootstrap';

const Carregando = props => {
  const { show = false } = props;

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} className="bg-invisible">
        <Row className="justify-content-center">
          <Spinner animation="grow" role="Carregando" />
          <span>Carregando...</span>
        </Row>
      </Modal>
    );
  }
  return null;
};

export default Carregando;
