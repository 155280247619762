import React, { useState } from 'react';
import { Container, Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { withRouter, useHistory } from 'react-router-dom';
import { Carregando } from '../../components';
import { Auth } from '../../services';
import { alertar, validarEmail } from '../../utils';

export default withRouter(() => {
  const [carregando, setCarregando] = useState(false);
  const { handleSubmit, register, errors, reset, control } = useForm();
  const [done, setDone] = useState(false);
  const [reenvio, setReenvio] = useState(false);
  const history = useHistory();

  const onSubmitCadastro = async values => {
    const { nome, email, senha, senhaConfirmacao, telefone } = values;

    setCarregando(true);

    try {
      // Cadastrar
      const resp = await Auth.cadastrarComEmail({ nome, email, senha, senhaConfirmacao, telefone });

      if (resp === true) {
        setDone(true);
        setCarregando(false);
      } else {
        console.log('resp', resp);
        const erro = { msg: resp.msg || 'Erro ao realizar o cadastro. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  const onSubmitReenvio = async values => {
    const { emailReenvio, senhaReenvio } = values;

    setCarregando(true);

    try {
      // Cadastrar
      const resp = await Auth.reenviarEmailAtivacao(emailReenvio, senhaReenvio);

      if (resp === true) {
        setDone(true);
        setCarregando(false);
      } else {
        const erro = { msg: 'Erro ao realizar o envio do e-mail. Tente novamente em alguns minutos.' };
        throw erro;
      }
    } catch (e) {
      alertar('error', e.msg || e);
      setCarregando(false);
    }
  };

  if (done) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
        <Container style={{ minWidth: 300 }}>
          <Card className="text-center" xs="auto">
            <Card.Body>
              <Card.Title className="text-center">CADASTRO</Card.Title>
              <Card.Text>Uma mensagem com o link de ativação foi encaminhada para seu e-mail.</Card.Text>
              <Card.Text>Caso não chegue em alguns minutos, verifique a caixa de Spam ou se há algum bloqueio em sua conta de e-mail.</Card.Text>

              <Button variant="outline-primary" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }

  if (reenvio) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
        <Container style={{ minWidth: 300 }}>
          <Carregando show={carregando} />

          <Card xs="auto">
            <Card.Body>
              <Card.Title className="text-center">ATIVAÇÃO</Card.Title>
              <Form onSubmit={handleSubmit(onSubmitReenvio)} className="mt-2">
                <Form.Row className="justify-content-between">
                  {/* Email */}
                  <Form.Group as={Col} controlId="email" className="align-middle mb-0">
                    <Form.Label column sm="auto">
                      E-mail
                    </Form.Label>
                    <Col xs="auto">
                      <Form.Control
                        name="emailReenvio"
                        type="text"
                        placeholder="email@provedor.com"
                        ref={register({
                          required: 'É necessário informar o e-mail do usuário.',
                          validate: value => validarEmail(value) || 'Formato do e-mail inválido.',
                        })}
                      />
                    </Col>
                    <Col as={Card.Text} className="text-danger">
                      {errors.emailReenvio && errors.emailReenvio.message}
                    </Col>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-between">
                  {/* Senha */}
                  <Form.Group as={Col} controlId="senhaReenvio" className="align-middle mb-0">
                    <Form.Label column sm="auto">
                      Senha utilizada no cadastro
                    </Form.Label>
                    <Col xs="auto">
                      <Form.Control
                        name="senhaReenvio"
                        type="password"
                        ref={register({
                          required: 'É necessário informar uma senha.',
                          minLength: { value: 6, message: 'A senha deve conter no mínimo 6 caracteres.' },
                          maxLength: { value: 150, message: 'A senha deve conter no máximo 150 caracteres.' },
                        })}
                      />
                    </Col>
                    <Col as={Card.Text} className="text-danger">
                      {errors.senhaReenvio && errors.senhaReenvio.message}
                    </Col>
                  </Form.Group>
                </Form.Row>

                {/* Botões */}
                <Row className="mt-4">
                  <Col className="d-flex justify-content-center">
                    <Button variant="outline-primary" onClick={() => setReenvio(false)}>
                      Voltar
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Button variant="outline-dark" onClick={() => reset()}>
                      Limpar
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-center">
                    <Button variant="primary" type="submit">
                      Enviar email
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
      <Container style={{ minWidth: 300 }}>
        <Carregando show={carregando} />
        <br />
        <Card xs="auto">
          <Card.Body>
            <Card.Title className="text-center">CADASTRO</Card.Title>
            <Form onSubmit={handleSubmit(onSubmitCadastro)} className="mt-2">
              <Form.Row className="justify-content-between mt-4">
                {/* Nome */}
                <Form.Group as={Col} controlId="nome" className="align-middle mb-0">
                  <Form.Label column sm="auto">
                    Nome
                  </Form.Label>
                  <Col xs="auto">
                    <Form.Control
                      name="nome"
                      type="text"
                      placeholder="Digite seu nome completo"
                      ref={register({
                        required: 'É necessário informar o nome completo.',
                        minLength: { value: 5, message: 'O nome deve conter no mínimo 5 caracteres.' },
                        maxLength: { value: 150, message: 'O nome deve conter no máximo 150 caracteres.' },
                      })}
                    />
                  </Col>
                  <Col as={Card.Text} className="text-danger">
                    {errors.nome && errors.nome.message}
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                {/* Email */}
                <Form.Group as={Col} controlId="email" className="align-middle mb-0">
                  <Form.Label column sm="auto">
                    E-mail
                  </Form.Label>
                  <Col xs="auto">
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="email@provedor.com"
                      ref={register({
                        required: 'É necessário informar o e-mail do usuário.',
                        validate: value => validarEmail(value) || 'Formato do e-mail inválido.',
                      })}
                    />
                  </Col>
                  <Col as={Card.Text} className="text-danger">
                    {errors.email && errors.email.message}
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                {/* Senha */}
                <Form.Group as={Col} controlId="senha" className="align-middle mb-0">
                  <Form.Label column sm="auto">
                    Senha
                  </Form.Label>
                  <Col xs="auto">
                    <Form.Control
                      name="senha"
                      type="password"
                      placeholder="Crie uma senha"
                      ref={register({
                        required: 'É necessário informar uma senha com no mínimo 6 caracteres.',
                        minLength: { value: 6, message: 'A senha deve conter no mínimo 6 caracteres.' },
                        maxLength: { value: 160, message: 'A senha deve conter no máximo 160 caracteres.' },
                      })}
                    />
                  </Col>
                  <Col as={Card.Text} className="text-danger">
                    {errors.senha && errors.senha.message}
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                {/* Repetir Senha */}
                <Form.Group as={Col} controlId="senhaConfirmacao" className="align-middle mb-0">
                  <Form.Label column sm="auto">
                    Repita a senha
                  </Form.Label>
                  <Col xs="auto">
                    <Form.Control
                      name="senhaConfirmacao"
                      type="password"
                      placeholder="Repita a senha"
                      ref={register({
                        required: 'É necessário informar uma senha com no mínimo 6 caracteres.',
                        minLength: { value: 6, message: 'A senha deve conter no mínimo 6 caracteres.' },
                        maxLength: { value: 160, message: 'A senha deve conter no máximo 160 caracteres.' },
                      })}
                    />
                  </Col>
                  <Col as={Card.Text} className="text-danger">
                    {errors.senhaConfirmacao && errors.senhaConfirmacao.message}
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-between">
                {/* Telefone */}
                <Form.Group as={Col} controlId="telefone" className="align-middle mb-0">
                  <Form.Label column sm="auto">
                    Telefone
                  </Form.Label>
                  <Col xs="auto">
                    <Controller
                      control={control}
                      name="telefone"
                      rules={{
                        required: false,
                        validate: value =>
                          `${value}`.replaceAll('(', '').replaceAll(')', '').replaceAll('_', '').replaceAll('-', '').replaceAll(' ', '') !== '' ||
                          'Telefone inválido.',
                      }}
                      defaultValue="(__) _____-____"
                      render={maskprops => (
                        <InputMask
                          value={maskprops.value}
                          onChange={maskprops.onChange}
                          inputRef={maskprops.ref}
                          className="form-control"
                          mask="(99) 99999-9999"
                          placeholder="(__) _____-____"
                        />
                      )}
                    />
                  </Col>
                  <Col as={Card.Text} className="text-danger">
                    {errors.telefone && errors.telefone.message}
                  </Col>
                </Form.Group>
              </Form.Row>

              {/* Botões */}
              <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                  <Button variant="outline-primary" onClick={() => history.goBack()}>
                    Voltar
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Button variant="outline-dark" onClick={() => reset()}>
                    Limpar
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Button variant="primary" type="submit">
                    Cadastrar
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Button variant="link" onClick={() => setReenvio(true)}>
                    Reenviar email de ativação
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
});
