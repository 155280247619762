import React, { useState } from 'react';
import { api } from '../constants';

/**
 * UserPlayer
 *
 * @param {number} idItem
 * @param {boolean} audio
 * @param {function} onEnd
 * @returns
 */
const UserPlayer = props => {
  const { idItem, audio = true, onEnd = () => {}, onLoad = () => {} } = props;
  const [erro, setErro] = useState(false);

  const ended = () => {
    // const aud = document.getElementById(`audio-id${idItem}`);
    // console.log('duration', aud.duration);
    onEnd();
  };

  if (erro) {
    return <i className="fa fa-times fa-lg text-danger" alt="Erro ao carregar áudio."></i>;
  }

  const servico = api.AUDIO;
  const url = servico.url(idItem);
  return (
    <audio
      id={`audio-id${idItem}`}
      // autoPlay
      onEnded={() => ended()}
      onError={() => setErro(true)}
      onCanPlay={() => onLoad()}
      controls={false}
      muted={!audio}
    >
      <source src={url} type="audio/ogg" />
      Seu navegador não suporta este formato de áudio.
    </audio>
  );
};

export default UserPlayer;
