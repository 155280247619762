import React from 'react';
import { AdmDadosGrupoDetalhe, AdmDadosGrupoLista, AdmDadosGrupoNovo } from '.';

export default props => {
  const { query } = props;
  const codigo = query.get('codigo');
  const novoItem = query.get('novoItem');

  if (novoItem) {
    return <AdmDadosGrupoNovo />;
  }

  if (codigo) {
    return <AdmDadosGrupoDetalhe query={query} />;
  }

  return <AdmDadosGrupoLista query={query} />;
};
