import React from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export default props => {
  const { novo = true, onSubmit = () => {}, onCancel = () => {}, defaults = { nome: '', nivel: '' } } = props;
  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
      <Form.Row className="justify-content-between mt-2">
        {/* Código */}
        {novo === true ? (
          <Form.Group as={Col} sm="auto" controlId="codigo" className="align-middle mb-0 pr-4">
            <Form.Label>Código</Form.Label>
            <Form.Control name="codigo" type="text" maxLength={100} ref={register({ required: 'É necessário preencher o código do grupo.' })} />
            <Col as={Card.Text} className="text-danger">
              {errors.codigo && errors.codigo.message}
            </Col>
          </Form.Group>
        ) : null}
        {/* Nome */}
        <Form.Group as={Col} controlId="nome" className={`align-middle mb-0 ${novo ? 'px-4' : 'pr-4'}`}>
          <Form.Label>Nome do Grupo</Form.Label>
          <Form.Control
            name="nome"
            type="text"
            maxLength={290}
            defaultValue={defaults.nome}
            ref={register({ required: 'É necessário preencher um nome para o grupo.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.nome && errors.nome.message}
          </Col>
        </Form.Group>
        {/* Nível */}
        <Form.Group as={Col} sm="auto" controlId="nivel" className="align-middle mb-0 px-4">
          <Form.Label>Nível</Form.Label>
          <Form.Control
            name="nivel"
            type="text"
            maxLength={50}
            defaultValue={defaults.nivel}
            ref={register({ required: 'É necessário preencher um nível para o grupo.' })}
          />
          <Col as={Card.Text} className="text-danger">
            {errors.nivel && errors.nivel.message}
          </Col>
        </Form.Group>
      </Form.Row>
      <Form.Row className="justify-content-center mt-4">
        <Col xs="auto">
          {novo === true ? (
            <Button className="mx-2" variant="outline-secondary" onClick={() => history.push('/admin/dados?aba=grupos')}>
              Voltar
            </Button>
          ) : (
            <Button className="mx-2" variant="danger" onClick={() => onCancel()}>
              Cancelar
            </Button>
          )}
          <Button className="mx-2" variant="outline-dark" type="reset">
            Limpar
          </Button>
          <Button className="mx-2" variant="success" type="submit">
            Salvar
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};
