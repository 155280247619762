import React from 'react';
import { Col, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default () => {
  return (
    <Col className="bg-light border-right pt-3" xs="auto">
      <Nav className="flex-column">
        <Nav.Item>
          <LinkContainer to="/admin/usuarios">
            <Nav.Link className="text-dark border-bottom ml-2">Gestão de Usuários</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/admin/dados">
            <Nav.Link className="text-dark border-bottom ml-2">Banco de Dados</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/admin/dashboards">
            <Nav.Link className="text-dark border-bottom ml-2">Resumos e Dashboards</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </Nav>
    </Col>
  );
};
