import { validarTipo } from '../utils';
import Requisicoes from './Requisicoes';

/**
 * Admin
 * Classe singleton
 *
 * @export
 * @class Admin
 */
export default class Admin {
  /**
   * Cria uma instância singleton da classe Admin.
   * @memberof Admin
   */
  constructor() {
    if (!Admin.instance) {
      Admin.instance = this;
    }
    return Admin.instance;
  }

  /**
   * listarUsuarios
   *
   * @returns
   * @memberof Admin
   */
  static async listarUsuarios() {
    const resp = await Requisicoes.getListarUsuarios();
    return resp;
  }

  /**
   * consultarUsuario
   *
   * @param {string} uid
   * @returns
   * @memberof Admin
   */
  static async consultarUsuario(uid) {
    if (!validarTipo(uid, 'string')) return { erro: 'UID inválido.' };
    const resp = await Requisicoes.getConsultarUsuario(uid);
    return resp;
  }

  /**
   * alterarStatusMatricula
   *
   * @param {string} id
   * @param {boolean} matriculada
   * @returns
   * @memberof Admin
   */
  static async alterarStatusMatricula(id, matriculada) {
    if (!validarTipo(id, 'string')) return { erro: 'ID inválido.' };
    if (!validarTipo(matriculada, 'boolean')) return { erro: 'Status inválido.' };
    const resp = await Requisicoes.patchAlterarStatusMatricula(id, { matriculada });
    return resp;
  }

  /**
   * alterarStatusCrianca
   *
   * @param {string} uid
   * @param {string} id
   * @param {string} status
   * @returns
   * @memberof Admin
   */
  static async alterarStatusCrianca(uid, id, status) {
    if (!validarTipo(uid, 'string')) return { erro: 'Uid inválido.' };
    if (!validarTipo(id, 'string')) return { erro: 'ID inválido.' };
    if (!validarTipo(status, 'string')) return { erro: 'Status inválido.' };
    const resp = await Requisicoes.patchAlterarStatusCrianca(id, { uid, status });
    return resp;
  }

  /**
   * alterarStatusUsuario
   *
   * @param {string} uid
   * @param {string} status
   * @returns
   * @memberof Admin
   */
  static async alterarStatusUsuario(uid, status) {
    if (!validarTipo(uid, 'string')) return { erro: 'ID inválido.' };
    if (!validarTipo(status, 'string')) return { erro: 'Status inválido.' };
    const resp = await Requisicoes.patchAlterarStatusUsuario(uid, { status });
    return resp;
  }

  /**
   * adicionarGrupo
   *
   * @param {object} grupo
   * @returns
   * @memberof Admin
   */
  static async adicionarGrupo(grupo) {
    if (!validarTipo(grupo, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.postAdicionarGrupo(grupo);
    return resp;
  }

  /**
   * listarGrupos
   *
   * @returns
   * @memberof Admin
   */
  static async listarGrupos() {
    const resp = await Requisicoes.getListarGrupos();
    return resp;
  }

  /**
   * consultarGrupoPorCodigo
   *
   * @param {string} codigo
   * @returns
   * @memberof Admin
   */
  static async consultarGrupoPorCodigo(codigo) {
    if (!validarTipo(codigo, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.getConsultarGrupoPorCodigo(codigo);
    return resp;
  }

  /**
   * alterarGrupo
   *
   * @param {object} grupo
   * @returns
   * @memberof Admin
   */
  static async alterarGrupo(grupo) {
    if (!validarTipo(grupo, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.patchAlterarGrupo(grupo);
    return resp;
  }

  /**
   * excluirGrupo
   *
   * @param {string} codigo
   * @returns
   * @memberof Admin
   */
  static async excluirGrupo(codigo) {
    if (!validarTipo(codigo, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.deleteExcluirGrupo(codigo);
    return resp;
  }

  /**
   * adicionarPrograma
   *
   * @param {object} programa
   * @returns
   * @memberof Admin
   */
  static async adicionarPrograma(programa) {
    if (!validarTipo(programa, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.postAdicionarPrograma(programa);
    return resp;
  }

  /**
   * listarProgramas
   *
   * @returns
   * @memberof Admin
   */
  static async listarProgramas() {
    const resp = await Requisicoes.getListarProgramas();
    return resp;
  }

  /**
   * consultarProgramaPorCodigo
   *
   * @param {string} codigo
   * @returns
   * @memberof Admin
   */
  static async consultarProgramaPorCodigo(codigo) {
    if (!validarTipo(codigo, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.getConsultarProgramaPorCodigo(codigo);
    return resp;
  }

  /**
   * alterarPrograma
   *
   * @param {object} programa
   * @returns
   * @memberof Admin
   */
  static async alterarPrograma(programa) {
    if (!validarTipo(programa, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.patchAlterarPrograma(programa);
    return resp;
  }

  /**
   * excluirPrograma
   *
   * @param {string} codigo
   * @returns
   * @memberof Admin
   */
  static async excluirPrograma(codigo) {
    if (!validarTipo(codigo, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.deleteExcluirPrograma(codigo);
    return resp;
  }

  /**
   * vincularGrupoPrograma
   *
   * @param {object} vinculo
   * @returns
   * @memberof Admin
   */
  static async vincularGrupoPrograma(vinculo) {
    if (!validarTipo(vinculo, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.patchVincularGrupoPrograma(vinculo);
    return resp;
  }

  /**
   * vincularItemGrupo
   *
   * @param {object} vinculo
   * @returns
   * @memberof Admin
   */
  static async vincularItemGrupo(vinculo) {
    if (!validarTipo(vinculo, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.patchVincularItemGrupo(vinculo);
    return resp;
  }

  /**
   * adicionarItem
   *
   * @param {object} item
   * @returns
   * @memberof Admin
   */
  static async adicionarItem(item) {
    if (!validarTipo(item, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.postAdicionarItem(item);
    return resp;
  }

  /**
   * listarItens
   *
   * @returns
   * @memberof Admin
   */
  static async listarItens() {
    const resp = await Requisicoes.getListarItens();
    return resp;
  }

  /**
   * consultarItemPorId
   *
   * @param {string} id
   * @returns
   * @memberof Admin
   */
  static async consultarItemPorId(id) {
    if (!validarTipo(id, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.getConsultarItemPorId(id);
    return resp;
  }

  /**
   * alterarItem
   *
   * @param {object} item
   * @returns
   * @memberof Admin
   */
  static async alterarItem(item) {
    if (!validarTipo(item, 'object')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.patchAlterarItem(item);
    return resp;
  }

  /**
   * excluirItem
   *
   * @param {string} id
   * @returns
   * @memberof Admin
   */
  static async excluirItem(id) {
    if (!validarTipo(id, 'string')) return { erro: 'Informações inválidas.' };
    const resp = await Requisicoes.deleteExcluirItem(id);
    return resp;
  }
}
